import { gql } from "@apollo/client";

export const GET_ACTIVITIES = gql`
  query getAllActivities(
    $number: Int
    $page: Int
    $title: String
    $world: ID
    $beginDate: Date
    $endDate: Date
    $canChoosePlayers: Boolean
    $canBeMultiPlayer: Boolean
    $close: Boolean
  ) {
    getAllActivities(
      infos: {
        number: $number
        page: $page
        title: $title
        world: $world
        beginDate: $beginDate
        endDate: $endDate
        canChoosePlayers: $canChoosePlayers
        canBeMultiPlayer: $canBeMultiPlayer
        close: $close
      }
    ) {
      id
      title
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      world
      unityPassId
      images
      beginDate
      endDate
      close
      canChoosePlayers
      canBeMultiPlayer
      endRules {
        time
        laps
        calories
      }
    }
  }
`;

export const GET_ACTIVITY = gql`
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      id
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      world
      unityPassId
      images
      beginDate
      endDate
      close
      canChoosePlayers
      canBeMultiPlayer
      category
      endRules {
        time
        laps
        calories
      }
    }
  }
`;

export const DELETE_ACTIVITIES = gql`
  mutation deleteMultipleActivities($ids: [ID!]!) {
    deleteMultipleActivities(ids: $ids)
  }
`;

export const UPDATE_ACTIVITY_IMAGE = gql`
  mutation updateActivityImage($id: ID!, $image: Upload!, $oldImage: String) {
    updateActivityImage(id: $id, image: $image, oldImage: $oldImage) {
      id
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      world
      unityPassId
      images
      beginDate
      endDate
      close
      canChoosePlayers
      canBeMultiPlayer
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateActivity(
    $id: ID!
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $oldImage: String
    $beginDate: Date
    $endDate: Date
    $canChoosePlayers: Boolean
    $canBeMultiPlayer: Boolean
    $unityPassId: Int
    $world: ID
    $time: Int
    $laps: Int
    $calories: Int
    $close: Boolean
    $category: String
  ) {
    updateActivity(
      id: $id
      infos: {
        time: $time
        laps: $laps
        calories: $calories
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        world: $world
        oldImage: $oldImage
        beginDate: $beginDate
        endDate: $endDate
        canChoosePlayers: $canChoosePlayers
        canBeMultiPlayer: $canBeMultiPlayer
        close: $close
        unityPassId: $unityPassId
        category: $category
      }
    ) {
      id
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      world
      unityPassId
      images
      beginDate
      endDate
      close
      canChoosePlayers
      canBeMultiPlayer
      endRules {
        time
        laps
        calories
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $beginDate: Date
    $endDate: Date
    $unityPassId: Int
    $canChoosePlayers: Boolean
    $canBeMultiPlayer: Boolean
    $world: ID
    $time: Int
    $laps: Int
    $calories: Int
    $category: String
  ) {
    createActivity(
      infos: {
        time: $time
        laps: $laps
        calories: $calories
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        world: $world
        beginDate: $beginDate
        endDate: $endDate
        canChoosePlayers: $canChoosePlayers
        canBeMultiPlayer: $canBeMultiPlayer
        unityPassId: $unityPassId
        category: $category
      }
    ) {
      id
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      world
      unityPassId
      images
      beginDate
      endDate
      close
      canChoosePlayers
      canBeMultiPlayer
      category
    }
  }
`;
