export const launchUpdateBadge = async (id, infos, updateBadge) => {
    const { titleAll, subtitleAll, image } = infos;
    try {
      await updateBadge({
        variables: {
          id,
          titleFr: titleAll?.fr,
          titleEN: titleAll?.en,
          subFr: subtitleAll?.fr,
          subEN: subtitleAll?.en,
          image: image?.indexOf('base64') !== -1  ? image : undefined
        },
        // update: (cache, res) => {}
      });
    } catch (error) {
      console.log("error", error);
    }
};