export const handleCreateWorld = async (onClose, refetch, infos, setInfos, createWorld, images, updateWorldImage) => {
  try {
      const { title, subtitle } = infos;
      createWorld({
        variables: {
          titleEn: title?.en ? title?.en : undefined,
          titleFr: title?.fr ? title?.fr : undefined,
          subEn: subtitle && subtitle?.en ? subtitle?.en : undefined,
          subFr: subtitle && subtitle?.fr ? subtitle?.fr : undefined
        },
        update: (cache, res) => {
          if (res && res.data && res.data.createWorld) {
            refetch();
            handleAddNewImage(res.data.createWorld.id, images, updateWorldImage);
          }
        }
      });

      setInfos(undefined);
      onClose();
      refetch();
    } catch (error) {
      console.log("error", error);
    }
};

 const handleAddNewImage = (id, images, updateWorldImage) => {
    for (let i = 0; i < images.length; i++) {
      try {
        updateWorldImage({
          variables: {
            id,
            newImage: images[i]
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

export const manageImages = (image, toDelete, images, setImages) => {
  const newImages = [...images];

  if (toDelete) {
    const index = newImages.findIndex((e) => e === image);
    if (index > -1) newImages.splice(index, 1);
  } else {
    newImages.push(image);
  }
  setImages(newImages);
};