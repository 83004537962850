export const launchUpdateChallenge = async (id, infos, updateChallenge ) => {
  const {
    titleAll,
    subtitleAll,
    ...fields
  } = infos;
    try {
      updateChallenge({
        variables: {
          id,
          titleFr: titleAll?.fr,
          titleEN: titleAll?.en,
          subFr: subtitleAll?.fr,
          subEN: subtitleAll?.en,
          ...fields
        },
      });
    } catch (error) {
      console.log("error", error);
    }
};

