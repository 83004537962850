import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({id, setId, setActivity, setTitle }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={7}>
          <Input
            title={t("input:filterWorldId.label")}
            placeholder={t("input:filterWorldId.placeholder")}
            onChange={setId}   
            debounceTimeout={500}
          />
        </Col>

        <Col span={7}>
          <Input
            title={t("input:filterActivityId.label")}
            placeholder={t("input:filterActivityId.placeholder")}
            onChange={setActivity} 
            debounceTimeout={500}
          />
        </Col>
        
        <Col span={7}>
          <Input
            title={t("input:filterName.label")}
            placeholder={t("input:filterName.placeholder")}
            onChange={setTitle}
            debounceTimeout={500}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
