import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import CheckboxCustom from "../../Components/CheckboxCustom";
import InputNumber from "../../Components/InputNumber";
import "../../UI/CommonStyles.less";
import { UPDATE_ACCOUNTPLAN, GET_ACCOUNTPLAN } from "./Requests";
import { useMutation, useQuery } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import { useTranslation } from "react-i18next";
import { launchUpdateAccountPlan } from "./Utility/EditAccountPlanUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditAccountPlan = ({ onClose, id, history, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateAccountPlan] = useMutation(UPDATE_ACCOUNTPLAN);
  const { data } = useQuery(GET_ACCOUNTPLAN, {
    variables: {
      id
    }
  });

  useEffect(() => {
    if (data?.getAccountPlan) {
      const { duration, ...fields } = data.getAccountPlan;
      setInfos({ duration: duration / 60000 / 60 / 24, ...fields });
    }
  }, [data]);

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getAccountPlan });
    let result = false;
    if (Object.keys(modifiedInfos).length > 0)
      result = await launchUpdateAccountPlan(modifiedInfos, updateAccountPlan, id, onClose);
    if (result) {
      setInfos(undefined);
      refetch();
    }
  }

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  return infos ? (
    <EditContainer
      title={t("Edit AccountPlan")}
      onClose={onClose}
      onSave={beforeUpdate}
      saveDisabled={!checkIfHasChanged(infos, {...data.getAccountPlan})}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            placeholder={t("input:name.placeholder")}
            value={infos.name}
            disabled={true}
            onChange={(value) => handleChange("name", value)}
          />
        </Col>

        <Col span={11}>
          <Input
            title={t("input:promotionalCode.label")}
            placeholder={t("input:promotionalCode.placeholder")}
            value={infos.promotionalCode}
            onChange={(value) => handleChange("promotionalCode", value)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <InputNumber
            title={t("input:duration.label")}
            placeholder={t("input:duration.placeholder")}
            value={infos.duration ? infos.duration : undefined}
            onChange={(e) => handleChange("duration", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Active")}
            defaultChecked={infos.active}
            onChange={(e) => handleChange("active", e)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Friendship")}
            defaultChecked={infos.friendship}
            onChange={(e) => handleChange("friendship", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:History Activities")}
            defaultChecked={infos.activityHistory}
            onChange={(e) => handleChange("activityHistory", e)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Basic Activities")}
            defaultChecked={infos.basicActivities}
            onChange={(e) => handleChange("basicActivities", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Multi Activities")}
            defaultChecked={infos.multiActivities}
            onChange={(e) => handleChange("multiActivities", e)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:VideoGame")}
            defaultChecked={infos.videogame}
            onChange={(e) => handleChange("videogame", e)}
          />
        </Col>
      </Row>
    </EditContainer>
  ) : null;
};

export default EditAccountPlan;
