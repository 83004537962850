import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "../../UI/CommonStyles.less";

const ButtonCustom = ({
  onClick,
  title,
  type,
  disabled,
  htmlType,
  icon,
  block,
  shape,
  size,
  className
}) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      disabled={disabled}
      htmlType={htmlType}
      icon={icon}
      block={block}
      shape={shape}
      size={size}
      className={className}
    >
      {title}
    </Button>
  );
};

ButtonCustom.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.object,
  block: PropTypes.bool,
  shape: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

ButtonCustom.defaultProps = {
  onClick: () => {},
  title: "",
  type: "",
  disabled: false,
  htmlType: "",
  block: false,
  shape: "",
  size: "",
  className: ""
};

export default ButtonCustom;
