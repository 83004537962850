import { gql } from '@apollo/client';

export const GET_ROOMS = gql`
  query getAllRooms (
    $user: ID
    $page: Int
    $number: Int
    $activity: ID
    $name: String
    $status: String
    $endDate: Date
    $beginDate: Date
    $creationDate: Date
  ) {
    getAllRooms (
      infos: {
        user: $user
        page: $page
        number: $number
        activity: $activity
        name: $name
        status: $status
        endDate: $endDate
        beginDate: $beginDate
        creationDate: $creationDate
      }
    ) {
      id
      activity {
        id
      }
      name
      status
      creationDate
      beginDate
      endDate
      admin {
        id
      }
      list {
        user {
          id
        }
        status
      }
    }
  }
`;

export const DELETE_ROOMS = gql`
  mutation deleteMultipleRooms ($ids: [ID]!) {
    deleteMultipleRooms (ids: $ids)
  }
`;

export const CREATE_ROOM = gql`
  mutation createRoom (
    $activity: ID
    $admin: ID
    $name: String
    $status: String
    $userList: [ID]
  ) {
    createRoom (
      infos: {
        activity: $activity
        admin: $admin
        name: $name
        status: $status
        userList: $userList
      }
    ) {
      id
      activity {
        id
      }
      name
      status
      creationDate
      beginDate
      endDate
      admin {
        id
      }
      list {
        user {
          id
        }
        status
      }
    }
  }
`;

export const UPDATE_ROOM = gql`
  mutation updateRoom (
    $id: ID
    $activity: ID
    $admin: ID
    $name: String
    $status: String
  ) {
    updateRoom (
      infos: {
        id: $id
        activity: $activity
        admin: $admin
        name: $name
        status: $status
      }
    ) {
      id
      activity {
        id
      }
      name
      status
      creationDate
      beginDate
      endDate
      admin {
        id
      }
      list {
        user {
          id
        }
        status
      }
    }
  }
`;

export const GET_ROOM = gql`
  query getRoom ($id: ID!) {
    getRoom (id: $id) {
      id
      activity {
        id
      }
      name
      status
      creationDate
      beginDate
      endDate
      admin {
        id
      }
      list {
        user {
          id
        }
        status
      }
    }
  }
`;

export const UPDATE_ROOM_USER_STATUS = gql`
  mutation updateRoomUserStatus (
    $id: ID!
    $status: String!
    $userId: ID
  ) {
      updateRoomUserStatus (
      id: $id
      status: $status
      userId: $userId
    ) {
      id
      activity {
        id
      }
      name
      status
      creationDate
      beginDate
      endDate
      admin {
        id
      }
      list {
        user {
          id
        }
        status
      }
    }
  }
`;
