import React, { useState } from "react";
import { Row, Col } from "antd";
import InputNumber from "../../Components/InputNumber";
import DropDown from "../../Components/DropDown";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Rules = ({ list, showFree, updateRuleType, updateRuleValue, dropdownInialState, inputInitialValue, disabled, color }) => {
  const { t } = useTranslation();
  const [ isFree, setIsFree ] = useState(showFree);
  const [ rule, setRule ] = useState(dropdownInialState);
  const [ value, setValue ] = useState(inputInitialValue);

  const beforeUpdateDropdown = (type, value) => {
    if (type === 'free')
      setIsFree(true);
    else if (type !== 'free' && isFree)
      setIsFree(false);
    updateRuleType(type, value);
    setRule(type);
  }

  const beforeUpdateInput = (value) => {
    setValue(value);
    updateRuleValue(rule, value);
  }

  return (
    <Row className="horizontalRowStyle">
      <Col span={11}>
        <DropDown
          title={t("dropDown:rules")}
          initialState={rule}
          onItemClicked={(e) => beforeUpdateDropdown(e, value)}
          items={list}
          color={disabled ? color : ""}
          disabled={disabled}
        />
      </Col>
      {
        rule !== 'free'  ? (
          <Col span={11}>
            <InputNumber
              onChange={(e) => beforeUpdateInput(e)}
              value={value ? value : undefined}
              disabled={disabled}
            />
          </Col>
        ) : null
      }
    </Row>
  )
};

Rules.propTypes = {
  list: PropTypes.array,
  showFree: PropTypes.bool,
  updateRuleType: PropTypes.func,
  updateRuleValue: PropTypes.func,
  dropdownInialState: PropTypes.string,
  inputInitialValue: PropTypes.number,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

Rules.defaultProps = {
  list: [],
  showFree: false,
  updateRuleType: () => {},
  updateRuleValue: () => {},
  dropdownInialState: 'free',
  inputInitialValue: undefined,
  disabled: false,
  color: "#a9a9a9"
};

export default Rules;