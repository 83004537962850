import React, { useState } from "react";
import { Layout, Table } from "antd";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Actions from "../../Components/Actions";
import EditWorld from "./EditWorld";
import { GET_WORLDS, DELETE_WORLDS } from "./Requests";
import { useQuery, useMutation } from "@apollo/client";
import Filters from "./Filters";
import CreateWorld from "./CreateWorld";
import { useTranslation } from "react-i18next";
import { getContentRows, getContentColumns, handleDeleteWorlds, checkIfNextDisabled } from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Worlds = () => {
  const { t } = useTranslation();
  const [number, setNumber] = useState(20);
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState(undefined);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(undefined);
  const [activity, setActivity] = useState(undefined);
  const [createModal, setCreateModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [worldClicked, setWorldClicked] = useState(undefined);
  const [deleteWorlds] = useMutation(DELETE_WORLDS);
  const { data, refetch } = useQuery(GET_WORLDS, {
    variables: { title, number, page, id, activity }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => setWorldClicked(record)
    };
  }

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDeleteWorlds(
      deleteWorlds, selectedRowKeys, 
      setSelectedRowKeys, refetch
    )
  }


  return (
    <Layout className="pageContainer">
      {createModal && (
        <CreateWorld
          refetch={refetch}  
          onClose={() => setCreateModal(false)} 
        />
      )}

      {worldClicked !== undefined && (
        <EditWorld
          refetch={refetch} 
          world={worldClicked}
          onClose={() => setWorldClicked(undefined)}
        />
      )}

      <Header name={t("WORLDS")}>
        <Filters
          id={id}
          setId={setId}
          setActivity={setActivity}
          setTitle={setTitle}
        />
      </Header>
      
      <Content className="card">
        <Actions
          add={() => setCreateModal(true)}
          disableDelete={selectedRowKeys.length === 0}
          deletePicked={() => setDeleteModal(true)}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              page={page}
              setPage={setPage}
              setNumber={setNumber}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Worlds;
