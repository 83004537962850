import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";
import moment from "moment";

export const getContentColumns = (t) => {
    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("TITLE")}</div>,
      dataIndex: "title",
      render: (title) => <div className="tableValue">{title}</div>
    },
    {
      title: <div className="tableHeading">{t("ON")}</div>,
      dataIndex: "close",
      render: (close) => (
        <React.Fragment>
          {close ? (
            <CloseCircleOutlined className="closeOutlined" />
          ) : (
            <CheckCircleOutlined className="checkOutlined" />
          )}
        </React.Fragment>
      )
    },
    {
      title: <div className="tableHeading">{t("BEGINDATE")}</div>,
      dataIndex: "beginDate",
      render: (date) => (
        <div className="tableValue">{readableDate(date)}</div>
      )
    },
    {
      title: <div className="tableHeading">{t("ENDDATE")}</div>,
      dataIndex: "endDate",
      render: (date) => (
        <div className="tableValue">{readableDate(date)}</div>
      )
    },
    {
      title: <div className="tableHeading">{t("STATUS")}</div>,
      dataIndex: "status",
      render: (status) => status
    }

  ];

  return columns;
}


export const getContentRows = (data, t) => {

  const getStatusValue = (beginDate, endDate) => {
    const today = new moment();
    if (today > moment(endDate))
      return <div className="buttonDone">{t("button:Close")}</div>;
    else if (today >= moment(beginDate) && today <= moment(endDate))
      return <div className="buttonOnGoing">{t("button:OnGoing")}</div>;
    else 
      return <div className="buttonPending">{t("button:Pending")}</div>;
  };

  return ( 
    !data || !data.getAllChallenges
      ? []
      : data.getAllChallenges
          .map(({ id, title, beginDate, endDate }) => ({
            key: id,
            id,
            title,
            status: getStatusValue(beginDate, endDate),
            beginDate,
            endDate
          })
      )
  )
}

export  const HandleDelete = async ( 
    deleteMultipleChallenge, selectedRowKeys, 
    setSelectedRowKeys, refetch 
  ) => {
    await deleteMultipleChallenge({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllChallenges || 
      data.getAllChallenges.length === 0 ||
      data.getAllChallenges.length < number
    ) return true;
  return false;
}

