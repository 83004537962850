import ButtonCustom from "../ButtonCustom";
import { Row, Col } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import LanguageSelector from "../../Pages/LanguageSelector";

export const FooterMenu = ({ setCreateModal, t, history}) => (
  <div>
    <ButtonCustom
      onClick={() => setCreateModal(true)}
      title={t("button:Contact Eveia")}
      className="buttonContact"
      block={true}
    />

    <ButtonCustom
      onClick={() => history.push("/logout")}
      title={t("button:Logout")}
      type="ghost"
      icon={<LogoutOutlined />}
      className="logout"
      block={true}
    />
  </div>
);

export const HeaderMenu = ({logo}) => (
  <Row className="headerMenu">
    <Col span={15} className="logoMenuContainer">
      <img
        className="logoMenu"
        src={logo}
        alt="img"
      />
    </Col>
    <Col span={9} className="traduction">
      <LanguageSelector />
    </Col>
  </Row>
);