import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: 'copyId',
      render: id => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("common:NAME")}</div>,
      dataIndex: 'name',
      render: (name) => <div className="tableValue">{name}</div>
    }
  ];

  return columns;
}


export const getContentRows = (data) => {
  return ( 
   !data || !(data.getAllCompanies)
      ? []
      : data.getAllCompanies.map(({ id, name }) => ({
        key: id,
        copyId: id,
        id,
        name
        })
      )
  )
}

export const handleDeleteComapnies = async (
    selectedRowKeys, setSelectedRowKeys, 
    refetch, deleteCompanies
  ) => {
    console.log('handleDeleteComapnies', selectedRowKeys)
    await deleteCompanies({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch(error => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllCompanies || 
      data.getAllCompanies.length === 0 ||
      data.getAllCompanies.length < number
    ) return true;
  return false;
}
