
export const getModifiedFields = (newInfos, oldInfos) => {
  const infos = {};
  for (const elem in newInfos) {
    if (newInfos[elem] !== oldInfos[elem])
      infos[elem] = newInfos[elem];
  }
  return infos;
}

export const checkIfHasChanged = (infos, oldInfos) => {
  if (Object.keys(getModifiedFields(infos, oldInfos)).length > 0)
    return true;
  return false;
};