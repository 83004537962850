import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import CheckboxCustom from "../../Components/CheckboxCustom";
import DatePickerCustom from "../../Components/DatePickerCustom";
import InputNumber from "../../Components/InputNumber";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import {
  UPDATE_ACTIVITY,
  GET_ACTIVITY,
  UPDATE_ACTIVITY_IMAGE
} from "./Requests";
import { useMutation, useQuery } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import Picture from "../../Components/Picture";
import DropDown from "../../Components/DropDown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  launchUpdateActivity,
  handleDropdownChange,
  getInitialStateDropDown,
  handleUpdateImageMutation,
  handleDeleteImageMutation
} from "./Utility/EditActivityUtility";
import Rules from "../../Components/Rules";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditActivity = ({ onClose, id, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateActivityImage] = useMutation(UPDATE_ACTIVITY_IMAGE);
  const [updateActivity] = useMutation(UPDATE_ACTIVITY);
  const { data, loading, error } = useQuery(GET_ACTIVITY, {
    fetchPolicy: "network-only",
    variables: {
      id
    }
  });

  useEffect(() => {
    if (data && data.getActivity && !infos)
      setInfos({ ...data.getActivity, ...data.getActivity.endRules });
  }, [data]);

  const activityRules = ["free", "calories", "time", "laps"];
  const activityType = [
    "is single player",
    "is multiplayer",
    "can be multiplayer"
  ];
  const categoryType = ["food", "locality", "meditation"];

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const getRuleInitialState = () => {
    if (!infos) return;
    if (infos.calories) return { type: "calories", value: infos.calories };
    else if (infos.laps) return { type: "laps", value: infos.laps };
    else if (infos.time) return { type: "time", value: infos.time };
    return { type: "free", value: undefined };
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {
      ...data.getActivity,
      ...data.getActivity.endRules
    });
    if (Object.keys(modifiedInfos).length > 0)
      await launchUpdateActivity(id, modifiedInfos, updateActivity);
    setInfos(undefined);
    onClose();
    refetch();
  };

  if (loading || !data || !data.getActivity) return "Loading...";
  if (error) return "Can't get this user informations";

  const ruleInitialState = getRuleInitialState();
  if (!infos) return null;
  return infos ? (
    <EditContainer
      title={t("Edit Activity")}
      onSave={beforeUpdate}
      onClose={onClose}
      saveDisabled={
        !checkIfHasChanged(infos, {
          ...data.getActivity,
          ...data.getActivity.endRules
        })
      }
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        {infos.images.map((e, index) => (
          <Picture
            key={index + e}
            picture={e}
            onDelete={(e) =>
              handleDeleteImageMutation(e, infos, setInfos, updateActivity, id)
            }
            onChange={(e) =>
              handleUpdateImageMutation(
                e,
                infos.images[index],
                infos,
                setInfos,
                id,
                updateActivityImage
              )
            }
          />
        ))}
        <Picture
          dontShow={true}
          onChange={(e) =>
            handleUpdateImageMutation(
              e,
              null,
              infos,
              setInfos,
              id,
              updateActivityImage
            )
          }
        />
      </Col>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DropDown
            title={t("dropDown:Multi players options")}
            onItemClicked={(value) =>
              handleDropdownChange(value, infos, setInfos)
            }
            items={activityType}
            initialState={getInitialStateDropDown(
              infos.canBeMultiPlayer,
              infos.canChoosePlayers
            )}
          />
        </Col>
        <Col span={9} className="fullRight">
          <CheckboxCustom
            title={t("checkbox:Close")}
            defaultChecked={infos.close}
            onChange={(e) => handleChange("close", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <Input
            title={t("input:worldId.label")}
            placeholder={t("input:worldId.placeholder")}
            value={infos.world}
            onChange={(value) => handleChange("world", value)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <InputNumber
            title={t("input:unitiyPassId.label")}
            placeholder={t("input:unitiyPassId.placeholder")}
            value={infos.unityPassId ? infos.unityPassId : undefined}
            onChange={(e) => handleChange("unityPassId", e)}
          />
        </Col>
      </Row>
      <Row className="horizontalRowStyle">
        <Rules
          list={activityRules}
          inputInitialValue={ruleInitialState.value}
          dropdownInialState={ruleInitialState.type}
          disabled={true}
        />
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <DropDown
            key={infos?.category || "..."}
            title={t("dropDown:Category")}
            onItemClicked={(value) => handleChange("category", value)}
            items={categoryType}
            initialState={infos?.category || "..."}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            value={infos.beginDate ? moment(infos.beginDate) : undefined}
            onChange={(e) => handleChange("beginDate", e)}
          />
        </Col>
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            value={infos.endDate ? moment(infos.endDate) : undefined}
            onChange={(e) => handleChange("endDate", e)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.titleAll).map((e, index) =>
          index < Object.keys(infos.titleAll).length - 1 ? (
            <Col key={index} span={11}>
              <Input
                title={e}
                value={infos.titleAll[e]}
                onChange={(value) => beforeSetText("titleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.subtitleAll).map((e, index) =>
          index < Object.keys(infos.subtitleAll).length - 1 ? (
            <Col key={index + "e"} span={11}>
              <TextAreaInput
                title={e}
                rows={4}
                value={infos.subtitleAll[e]}
                onChange={(value) => beforeSetText("subtitleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>
    </EditContainer>
  ) : null;
};

export default EditActivity;
