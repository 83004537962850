import React, { useState, useEffect } from "react";
import { Col } from "antd";
import EditContainer from "../../Components/EditContainer";
import Input from "../../Components/Input";
import { useQuery, useMutation } from "@apollo/client";
import "../../UI/CommonStyles.less";
import Picture from "../../Components/Picture";
import { GET_COMPANY, UPDATE_COMPANY } from "./Requests";
import { useTranslation } from "react-i18next";
import { launchUpdateCompany } from "./Utility/EditCompanyUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditCompany = ({ id, onClose, history, refetch }) => {
  const { t } = useTranslation();
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const { data, loading, error } = useQuery(GET_COMPANY, { variables: { id } });
  const [infos, setInfos] = useState(undefined);

  useEffect(() => {
    if (data?.getCompany) setInfos(data.getCompany);
  }, [data]);

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getCompany });
    let result = false;
    if (Object.keys(modifiedInfos).length > 0)
      result = await launchUpdateCompany(modifiedInfos, updateCompany, id, onClose);
    if (result) {
      setInfos(undefined);
      refetch();
    }
  }

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  if (loading || !data || !data.getCompany || !infos) return "Loading...";
  if (error) return "Can't get this company informations";

  return (
    <EditContainer
      title={t("Edit Company")}
      onSave={beforeUpdate}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged(infos, { ...data.getCompany })}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        <Picture
          key={infos.logo}
          disableDelete={true}
          picture={infos.logo}
          onChange={(e) => handleChange("logo", e)}
        />
      </Col>

      <Col span={11}>
        <Input
          title={t("input:name.label")}
          placeholder={t("input:name.placeholder")}
          value={infos.name}
          onChange={(e) => handleChange("name", e)}
        />
      </Col>
    </EditContainer>
  );
};

export default EditCompany;
