import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import ButtonCustom from "../ButtonCustom";
import "./EditContainerStyle.less";
import { useTranslation } from "react-i18next";

const EditContainer = ({
  title,
  onSave,
  saveDisabled,
  onClose,
  contactModal,
  children
}) => {
  const [opened, setOpened] = useState(true);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpened(false);
    onClose();
  };

  return (
    <Modal
      className="editContainerWidth"
      visible={opened}
      maskClosable={false}
      onCancel={handleClose}
      onOk={onSave}
      footer={[
        <ButtonCustom
          key="back"
          title={t("button:CANCEL")}
          className="modalButton"
          onClick={handleClose}
        />,
        <ButtonCustom
          key="submit"
          title={t("button:OK")}
          className="modalButton"
          disabled={saveDisabled}
          type="primary"
          onClick={onSave}
        />
      ]}
      title={title}
      okButtonProps={{
        disabled: saveDisabled
      }}
      bodyStyle={
        contactModal
          ? {
              minHeight: 400,
              display: "flex",
              flexDirection: "column"
            }
          : {
              minHeight: 600,
              display: "flex",
              flexDirection: "column"
            }
      }
    >
      {children}
    </Modal>
  );
};

EditContainer.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  saveDisabled: PropTypes.bool,
  title: PropTypes.string,
  contactModal: PropTypes.bool
};

EditContainer.defaultProps = {
  onSave: () => {},
  onClose: () => {},
  saveDisabled: false,
  title: "Title",
  contactModal: false
};

export default EditContainer;
