import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import "../../UI/CommonStyles.less";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Filters = ({ setStatusFilter, setTitle }) => {

  const { t } = useTranslation();
  const statusRule = ["all", "pending", "onGoing", "close"];

  const filterStatus = (status) => {
    if (status === "all") 
      setStatusFilter(undefined);
    else 
      setStatusFilter(status);
  }

  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:filterTitle.label")}
            placeholder={t("input:filterTitle.placeholder")}
            onChange={(text) => setTitle(text)}
            debounceTimeout={500}
          />
        </Col>
        <Col span={6}>
          <DropDown
            title={t("dropDown:Status")}
            onItemClicked={filterStatus}
            items={statusRule}
            initialState="all"
             
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

Filters.propTypes = {
  setStatusFilter: PropTypes.func,
  setTitle: PropTypes.func
};

Filters.defaultProps = {
  setStatusFilter: () => {},
  setTitle: () => {}
};

export default Filters;
