import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Layout, Table } from 'antd';
import { GET_ROOMS, DELETE_ROOMS } from './Requests';
import Filters from './Filters';
import Footer from '../../Components/Footer';
import Actions from '../../Components/Actions';
import Header from '../../Components/Header';
import CreateRoom from './CreateRoom';
import '../../UI/CommonStyles.less';
import EditRoom from './EditRoom';
import { useTranslation } from "react-i18next";
import { getContentRows, getContentColumns, checkIfNextDisabled } from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Rooms = ({ location }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [create, setCreate] = useState(false);
  const [number, setNumber] = useState(20);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [nameFilter, setNameFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [beginDate, setBeginDate] = useState(undefined);
  const [creationDate, setCreationDate] = useState(undefined);
  const [roomClicked, setRoomClicked] = useState(undefined);
  const [userFilter, setUserFilter] = useState(location?.state?.userId ? location.state.userId : undefined);
  const [activityFilter, setActivityFilter] = useState(undefined);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteRooms] = useMutation(DELETE_ROOMS);
  const { data, refetch } = useQuery(GET_ROOMS, {
    variables: {
      user: userFilter,
      page,
      number,
      activity: activityFilter,
      name: nameFilter,
      status: statusFilter,
      endDate,
      creationDate,
      beginDate
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record, index) => {
    return {
      onClick: event => { 
        setRoomClicked(data.getAllRooms[index].id)
      }
    }
  }

  const handleDelete = async () => {
    try {
      await deleteRooms({
        variables: {
          ids: selectedRowKeys
        }
      });
      refetch();
      setSelectedRowKeys([]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDelete();
  }

  return (
    <Layout className="pageContainer">
      { create && 
      <CreateRoom 
        refetch={refetch} 
        onClose={() => setCreate(false)} 
      />
      }
      { roomClicked && 
      <EditRoom 
        refetch={refetch} 
        onClose={() => setRoomClicked(undefined)} 
        id={roomClicked} 
      />
      }
     
      <Header name={t("ROOMS")}>
        <Filters
          userFilter={userFilter}
          setUserFilter={setUserFilter}
          setActivityFilter={setActivityFilter}
          setNameFilter={setNameFilter}
          setStatusFilter={setStatusFilter}
          setCreationDate={setCreationDate}
          setBeginDate={setBeginDate}
          setEndDate={setEndDate}
        />
      </Header>
      
      <Content className="card">
        <Actions 
          disableDelete={!selectedRowKeys.length} 
          deletePicked={() => setDeleteModal(true)}
          add={() => setCreate(true)} />
        <Table 
          pagination={false} 
          rowSelection={rowSelection} 
          dataSource={getContentRows(data, t)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked} 
          footer={() =>
            <Footer
              page={page}
              setNumber={setNumber}
              setPage={setPage}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          }
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Rooms;