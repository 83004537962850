import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Layout, Table } from "antd";
import EditCompanyDevice from "./EditCompanyDevice";
import { GET_DEVICES } from "../Devices/Requests";
import { GET_ME } from "../Users/Requests";
import "../../UI/CommonStyles.less";
import Filters from "./Filters";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled
} from "./Utility/TableUtility";
const { Content } = Layout;

const CompanyDevices = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [company, setCompany] = useState(undefined);
  const [machine, setMachine] = useState("");
  const [user, setUser] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data, refetch } = useQuery(GET_DEVICES, {
    variables: {
      number,
      page,
      company,
      machine,
      user
    },
    fetchPolicy: "network-only"
  });

  const myData = useQuery(GET_ME);

  const companyId = myData?.data?.me?.company;
  useEffect(() => {
    if (companyId) {
      setCompany(companyId);
    }
  }, [companyId]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditCompanyDevice
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          id={idClicked}
        />
      )}

      <Header name={t("DEVICES")}>
        <Filters setUser={setUser} setMachine={setMachine} />
      </Header>

      <Content className="card">
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              setPage={setPage}
              setNumber={setNumber}
              page={page}
              number={number}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
    </Layout>
  );
};

export default CompanyDevices;
