import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (close, t) => {
  const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("TITLE")}</div>,
      dataIndex: "title",
      render: (title) => <div className="tableValue">{title}</div>
    },
    {
      title: <div className="tableHeading">{t("ON")}</div>,
      dataIndex: "close",
      render: (close) => (
        <React.Fragment>
          {close ? (
            <CloseCircleOutlined className="closeOutlined" />
          ) : (
            <CheckCircleOutlined className="checkOutlined" />
          )}
        </React.Fragment>
      )
    },
    {
      title: <div className="tableHeading">{t("BEGINDATE")}</div>,
      dataIndex: "beginDate",
      render: (date) => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("ENDDATE")}</div>,
      dataIndex: "endDate",
      render: (date) => <div className="tableValue">{readableDate(date)}</div>
    }
  ];

  return columns;
};

export const getContentRows = (data) => {
  return !data || !data.getAllActivities
    ? []
    : data.getAllActivities.map(({ 
      id, title, close, beginDate, endDate
    }) => ({
        key: id,
        id,
        title,
        beginDate,
        endDate,
        close
      }));
};

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllActivities || 
      data.getAllActivities.length === 0 ||
      data.getAllActivities.length < number
    ) return true;
  return false;
}

export const handleDropdownChange = (value, setCanChoosePlayers, setCanBeMultiPlayer) => {
    if (value === "all") {
      setCanChoosePlayers(null);
      setCanBeMultiPlayer(null);
    } else if (value === "is single player") {
      setCanChoosePlayers(null);
      setCanBeMultiPlayer(false);
    } else if (value === "is multiplayer") {
      setCanChoosePlayers(null);
      setCanBeMultiPlayer(true);
    } else if (value === "can be multiplayer") {
      setCanChoosePlayers(true);
      setCanBeMultiPlayer(true);
    }
  };