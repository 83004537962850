import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./CheckMailStyle.less";
import { Text } from "./styledComponent";
import LogoEveia from "../../assets/Images/LogoEveia.png";
import { useMutation, gql } from "@apollo/client";

const CHECK_MAIL_CONFIRMATON = gql`
  mutation checkMailConfirmation($token: String!) {
    checkMailConfirmation(token: $token)
  }
`;

const CheckMailConfiramtion = () => {
  let { token } = useParams();
  const [isValidMail, setisValidMail] = useState(null);
  const [checkMailConfirmation] = useMutation(CHECK_MAIL_CONFIRMATON, {
    onCompleted: (data) => setisValidMail(data.checkMailConfirmation)
  });

  useEffect(() => {
    checkMailConfirmation({
      variables: {
        token: token
      }
    });
  }, []);

  return (
    <div className="checkMailForm">
      <section className="checkMailSection">
        <img src={LogoEveia} alt="img" width="220" height="57" />
      </section>
      {isValidMail ? <Text>✅ Votre compte a été validé </Text> : <Text></Text>}
    </div>
  );
};

export default CheckMailConfiramtion;
