import React from "react";
import Users from "../../Pages/Users";
import Sessions from "../../Pages/Sessions";
import Activities from "../../Pages/Activities";
import Rooms from "../../Pages/Rooms";
import Worlds from "../../Pages/Worlds";
import Devices from "../../Pages/Devices";
import AccountPlans from "../../Pages/AccountPlans";
import Challenges from "../../Pages/Challenges";
import Companies from "../../Pages/Companies";
import AuthenticatedRoute from "../../Pages/App/AuthenticatedRoute";
import { Route, Switch, Redirect } from "react-router-dom";
import Logout from "../../Pages/Logout";
import Login from "../../Pages/Login";
import Badges from "../../Pages/Badges";
import ResetPassword from "../../Pages/ResetPassword";
import CheckMailConfirmation from "../../Pages/CheckMailConfirmation";

const AdminNavigator = ({ AuthContext, state }) => {
  return (
    <Switch>
      <Route exact path="/signin" AuthContext={AuthContext}>
        {(props) => <Login {...props} AuthContext={AuthContext} />}
      </Route>

      <Route exact path="/reset-password/:token">
        {(props) => <ResetPassword />}
      </Route>

      <Route exact path="/mail-confirmation/:token">
        {(props) => <CheckMailConfirmation />}
      </Route>

      <Route exact path="/logout" AuthContext={AuthContext}>
        {(props) => <Logout {...props} AuthContext={AuthContext} />}
      </Route>

      <AuthenticatedRoute
        path="/users"
        auth={state.userToken !== null}
        exact
        component={(props) => <Users AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="/sessions"
        auth={state.userToken !== null}
        exact
        component={(props) => <Sessions AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="/activities"
        auth={state.userToken !== null}
        exact
        component={(props) => (
          <Activities AuthContext={AuthContext} {...props} />
        )}
      />
      <AuthenticatedRoute
        path="/rooms"
        auth={state.userToken !== null}
        exact
        component={(props) => <Rooms AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        exact
        path="/devices"
        auth={state.userToken !== null}
        component={(props) => <Devices AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="/worlds"
        auth={state.userToken !== null}
        exact
        component={(props) => <Worlds AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="/accountPlans"
        auth={state.userToken !== null}
        exact
        component={(props) => (
          <AccountPlans AuthContext={AuthContext} {...props} />
        )}
      />
      <AuthenticatedRoute
        path="/companies"
        auth={state.userToken !== null}
        exact
        component={(props) => (
          <Companies AuthContext={AuthContext} {...props} />
        )}
      />
      <AuthenticatedRoute
        path="/challenges"
        auth={state.userToken !== null}
        exact
        component={(props) => (
          <Challenges AuthContext={AuthContext} {...props} />
        )}
      />
      <AuthenticatedRoute
        path="/badges"
        auth={state.userToken !== null}
        exact
        component={(props) => <Badges AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="/devices"
        auth={state.userToken !== null}
        exact
        component={(props) => <Devices AuthContext={AuthContext} {...props} />}
      />
      <AuthenticatedRoute
        path="*"
        auth={state.userToken !== null}
        exact
        component={() => <Redirect to="/users" />}
      />
    </Switch>
  );
};

export default AdminNavigator;
