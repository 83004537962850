import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Layout, Table } from "antd";
import { GET_CHALLENGES, DELETE_CHALLENGES } from "./Requests";
import "../../UI/CommonStyles.less";
import Filters from "./Filters";
import Footer from "../../Components/Footer";
import Actions from "../../Components/Actions";
import Header from "../../Components/Header";
import EditChallenge from "./EditChallenge";
import CreateChallenge from "./CreateChallenge";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled
} from "./Utility/TableUtility";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Challenges = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [title, setTitle] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteMultipleChallenge] = useMutation(DELETE_CHALLENGES);

  const { data, refetch } = useQuery(GET_CHALLENGES, {
    variables: {
      number,
      page,
      title,
      status: statusFilter
    },
    fetchPolicy: "network-only"
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const handleDelete = async () => {
    await deleteMultipleChallenge({
      variables: {
        ids: selectedRowKeys,
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDelete();
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditChallenge
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          id={idClicked}
        />
      )}

      {createModal && (
        <CreateChallenge
          refetch={refetch}
          onClose={() => setCreateModal(false)}
        />
      )}

      <Header name={t("CHALLENGES")}>
        <Filters setStatusFilter={setStatusFilter} setTitle={setTitle} />
      </Header>

      <Content className="card">
        <Actions
          add={() => setCreateModal(true)}
          deletePicked={() => setDeleteModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data, t)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              setPage={setPage}
              setNumber={setNumber}
              page={page}
              number={number}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Challenges;
