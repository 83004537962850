export const launchCreationChallenge = async (onClose, refetch, infos, setInfos, createChallenge, usersCompany) => {
  try {
      const { title, subtitle, calories, laps, beginDate, endDate, ...fields } = infos;
      await createChallenge({
        variables: {
          titleEN: title?.en ? title?.en : undefined,
          titleFr: title?.fr ? title?.fr : undefined,
          subEn: subtitle && subtitle.en ? subtitle.en : undefined,
          subFr: subtitle && subtitle.fr ? subtitle.fr : undefined,
          beginDate: beginDate ? beginDate.toDate() : undefined,
          endDate: endDate ? endDate.toDate() : undefined,
          calories: calories && calories > 0 ? calories : undefined,
          laps: laps && laps > 0 ? laps : undefined,
          usersCompany : undefined,
          ...fields
        },
        update: (cache, res) => {
          if(res) onClose();
        }
      });
      setInfos(undefined);
      refetch();
    } catch (error) {
      console.log("error", error);
    }
};

export const beforeSetUsersCompany = (name, data, setUsersCompany) => {
    const company = data?.getAllCompanies?.find((e) => e.name === name);
    if (company) setUsersCompany(company.id);
    else setUsersCompany(undefined);
  };
