import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import { getUrl } from '../../serviceWorker';

  const httpLink = new createUploadLink({
    uri: getUrl(),
    credentials: 'same-origin'
  });

  const wsLink = new WebSocketLink({
    uri: getUrl('wss'),
    options: { reconnect: true, lazy: true, },
    credentials: 'same-origin',
    connectionParams: async ()  => ({
      authToken: localStorage.getItem('userToken')
    })
  });

  const cache = new InMemoryCache();

  const authLink = setContext(async (_, { headers }) => {
    const token = localStorage.getItem('userToken');
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const httpAuthLink = authLink.concat(httpLink);

  const subscriptionMiddleware = {
    applyMiddleware: async (options, next) => {
      options.authToken = localStorage.getItem('userToken');
      next();
    }
  };

  wsLink.subscriptionClient.use([subscriptionMiddleware]);

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      
      graphQLErrors.map(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          return null;
      });
    }
    if (networkError)
      console.log(`[Network error]: ${networkError}`);
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpAuthLink
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      link
    ]),
    cache
  });

  export default client;