import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { UPDATE_WORLD, UPDATE_IMAGES } from "./Requests";
import { useMutation } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import Picture from "../../Components/Picture";
import { useTranslation } from "react-i18next";
import {
  launchUpdateWorld,
  handleAddNewImage,
  handleDeleteImage
} from "./Utility/EditWorldUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditWorld = ({ onClose, world, refetch }) => {
  
  const { t } = useTranslation();
  const [infos, setInfos] = useState(world);
  const [updateWorld] = useMutation(UPDATE_WORLD);
  const [updateWorldImage] = useMutation(UPDATE_IMAGES);

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, world);
    let result = false;
    if (Object.keys(modifiedInfos).length > 0)
      result = await launchUpdateWorld(modifiedInfos, updateWorld, world.id, onClose);
    if (result) {
      setInfos(undefined);
      refetch();
    }
  }

  return infos ? (
    <EditContainer
      title={t("Edit World")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged(infos, world)}
      onSave={beforeUpdate}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        {infos.images.map((image, index) => (
          <Picture
            key={index + "imageWorld"}
            onDelete={() => handleDeleteImage(
              image, updateWorldImage, infos, setInfos
            )}
            picture={image}
          />
        ))}
        <Picture dontShow={true} onChange={(e) => handleAddNewImage(
          e, updateWorldImage, infos, setInfos
        )} />
      </Col>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.titles).map((e, index) =>
          index >= Object.keys(infos.titles).length - 1 ? null : (
            <Col span={11} key={index}>
              <Input
                title={e}
                key={index}
                value={infos.titles[e]}
                onChange={(value) => beforeSetText("titles", e, value, infos, setInfos)}
              />
            </Col>
          )
        )}
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.subtitles).map((e, index) =>
          index >= Object.keys(infos.subtitles).length - 1 ? null : (
            <Col key={index + "e"} span={11}>
              <TextAreaInput
                title={e}
                rows={4}
                value={infos.subtitles[e]}
                onChange={(value) => beforeSetText("subtitles", e, value, infos, setInfos)}
              />
            </Col>
          )
        )}
      </Row>
    </EditContainer>
  ) : null;
};

export default EditWorld;
