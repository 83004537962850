import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

const Logout = ({ AuthContext }) => {
  const { signOut } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    signOut();
    history.push("/signin");
  });

  return <React.Fragment></React.Fragment>;
};

export default Logout;
