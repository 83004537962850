import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query getCompany ($id: ID!) {
    getCompany (id: $id) {
      id
      name
      logo
    }
  }
`;

export const GET_COMPANIES = gql`
  query getAllCompanies ($number: Int, $page: Int, $name: String) {
    getAllCompanies (infos: { number: $number, page: $page, name: $name }) {
      id
      name
      logo
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany ($name: String!, $logo: String) {
    createCompany (infos: {name: $name, logo: $logo}) {
      id
      name
      logo
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany ($name: String, $logo: String, $id: ID!) {
    updateCompany (id: $id, infos: { name: $name, logo: $logo }) {
      id
      name
      logo
    }
  }
`;

export const UPDATE_LOGO_COMPANY = gql`
  mutation updateLogoCompany ($logo: String!) {
    updateLogoCompany (logo: $logo) {
      id
      name
      logo
    }
  }
`;

export const DELETE_COMPANIES = gql`
  mutation deleteMultipleCompanies ($ids: [ID]!) {
    deleteMultipleCompanies (ids: $ids)
  }
`;
