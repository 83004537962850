import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "antd";
import CheckboxCustom from "../../Components/CheckboxCustom";
import { useMutation, useQuery } from "@apollo/client";
import Input from "../../Components/Input";
import InputNumber from "../../Components/InputNumber";
import { CREATE_USER } from "./Requests";
import EditContainer from "../../Components/EditContainer";
import { GET_ACCOUNTPLANS } from "../AccountPlans/Requests";
import DropDown from "../../Components/DropDown";
import { GET_COMPANIES } from "../Companies/Requests";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";
import { handleCreateUser } from "./Utility/CreateUserUtility";

const CreateUser = ({ onClose, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const { data } = useQuery(GET_ACCOUNTPLANS);
  const [createUser, { error }] = useMutation(CREATE_USER);
  const companiesRequest = useQuery(GET_COMPANIES, {
    fetchPolicy: "network-only"
  });
  const [plans, setPlans] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (data?.getAllAccountPlans) {
      setPlans(data.getAllAccountPlans);
    }
  }, [data]);

  useEffect(() => {
    if (error) setShowAlert(true);
  }, [error]);

  useEffect(() => {
    if (companiesRequest?.data?.getAllCompanies)
      setCompanies(companiesRequest.data.getAllCompanies);
  }, [companiesRequest]);

  const checkIfHasChanged = () => {
    if (
      infos?.firstName &&
      infos?.lastName &&
      infos?.email &&
      validatePassword(infos?.password)
    )
      return true;
    return false;
  };

  const validatePassword = (password) => {
    const regCaseLow = /^(?=.*[a-z]).*$/;
    const regCaseUp = /^(?=.*[A-Z]).*$/;
    const regNumber = /^(?=.*[0-9]).*$/;
    const regSpecial = /^(?=.*[^A-Za-z0-9]).*$/;

    if (
      password?.length >= 8 &&
      regCaseLow.test(password) &&
      regCaseUp.test(password) &&
      regNumber.test(password) &&
      regSpecial.test(password)
    )
      return true;
    return false;
  };

  const plansData = plans.map((item) => {
    return item.name;
  });

  const companiesData = companies.map((item) => {
    return item.name;
  });
  companiesData.unshift("...");

  const beforeSetPlan = (name) => {
    const plan = plans.find((e) => e.name === name);
    if (plan) {
      handleChange("plan", plan.id);
    }
  };

  const beforeSetCompany = (name) => {
    if (name === "...") {
      handleChange("company", "...");
      return;
    }
    const company = companies.find((e) => e.name === name);
    if (company) {
      handleChange("company", company.id);
    }
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  return (
    <EditContainer
      onSave={() =>
        handleCreateUser(onClose, refetch, infos, setInfos, createUser)
      }
      onClose={onClose}
      title={t("Create User")}
      saveDisabled={!checkIfHasChanged()}
    >
      {showAlert ? (
        <Alert
          message="Error"
          type="error"
          showIcon
          closable={true}
          onClose={() => setShowAlert(false)}
        />
      ) : null}
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:firstName.label")}
            onChange={(e) => handleChange("firstName", e)}
            placeholder={t("input:firstName.placeholder")}
          />
        </Col>
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            onChange={(e) => handleChange("lastName", e)}
            placeholder={t("input:name.placeholder")}
          />
        </Col>
      </Row>

      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:email.label")}
            onChange={(e) => handleChange("email", e)}
            placeholder={t("input:email.placeholder")}
          />
        </Col>
        <Col span={11}>
          <Row className="horizontalRowStyle">
            <Col span={7}>
              <Input
                title={t("input:code.label")}
                onChange={(e) => handleChange("countryCode", e.toString())}
                placeholder={t("input:code.placeholder")}
              />
            </Col>
            <Col span={15}>
              <InputNumber
                title={t("input:number.label")}
                onChange={(e) => handleChange("number", e.toString())}
                placeholder={t("input:number.placeholder")}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DropDown
            title={t("dropDown:Plan")}
            items={plansData}
            onItemClicked={beforeSetPlan}
            translate={false}
          />
        </Col>

        <Col span={11} className="fullRight">
          <CheckboxCustom
            title={t("checkbox:adminEveia")}
            onChange={(e) => handleChange("admin", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DropDown
            title={t("dropDown:Company")}
            items={companiesData}
            onItemClicked={beforeSetCompany}
            translate={false}
          />
        </Col>

        <Col span={11} className="fullRight">
          <CheckboxCustom
            title={t("checkbox:adminCompany")}
            onChange={(e) => handleChange("adminCompany", e)}
            disabled={infos?.company ? infos?.company === "..." : true}
          />
        </Col>
      </Row>
      <Row className="horizontalRowStyle">
        <Col span={24}>
          <Input
            title={t("input:password.label")}
            onChange={(e) => handleChange("password", e)}
            placeholder={t("input:password.placeholder")}
          />
        </Col>
      </Row>
    </EditContainer>
  );
};

export default CreateUser;
