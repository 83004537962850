export const launchUpdateActivity = async (id, infos, updateActivity) => {
    try {
      const { titleAll, subtitleAll, images, ...fields } = infos;

      console.log('INFOS', infos)
      await updateActivity({
        variables: {
          id,
          titleFr: titleAll?.fr,
          titleEN: titleAll?.en,
          subFr: subtitleAll?.fr,
          subEN: subtitleAll?.en,
          ...fields
        },
        update: (cache, res) => {}
      });
    } catch (error) {
      console.log("error", error);
    }
  };

export const handleDropdownChange = (value, infos, setInfos) => {
    const newInfos = { ...infos };
    if (value === "is single player") {
      newInfos.canChoosePlayers = false;
      newInfos.canBeMultiPlayer = false;
    } else if (value === "is multiplayer") {
      newInfos.canChoosePlayers = false;
      newInfos.canBeMultiPlayer = true;
    } else if (value === "can be multiplayer") {
      newInfos.canChoosePlayers = true;
      newInfos.canBeMultiPlayer = true;
    }
    setInfos(newInfos);
  };

export const getInitialStateDropDown = (canBeMultiPlayer, canChoosePlayers) => {
    if (canBeMultiPlayer && canChoosePlayers) return "can be multiplayer";
    else if (canBeMultiPlayer) return "is multiplayer";
    else return "is single player";
  };

export const handleUpdateImageMutation = async (
      image, oldImage, infos,
      setInfos, id, updateActivityImage
    ) => {
    const newInfos = { ...infos };
    if (oldImage) {
      const index = newInfos.images.findIndex((e) => e === oldImage);
      newInfos.images.splice(index, 1, image);
    } else {
      newInfos.images.push(image);
    }
    setInfos(newInfos);
    
    try {
      await updateActivityImage({
        variables: {
          image: image,
          oldImage: oldImage,
          id: id
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

export const handleDeleteImageMutation = async (
    oldImage, infos, setInfos, updateActivity, id
  ) => {
    const newInfos = { ...infos };
    const index = newInfos.images.findIndex((e) => e === oldImage);
    if (index > -1) {
      newInfos.images.splice(index, 1);
      setInfos(newInfos);
    }
    try {
      await updateActivity({
        variables: {
          oldImage: oldImage,
          id: id
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };