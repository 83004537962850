import React from 'react';
import { Modal } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_SESSION_LOGS } from './Requests';
import { Table } from 'antd';
import { useTranslation } from "react-i18next";

const ShowLogs = ({ sessionId, onClose }) => {
  const { t } = useTranslation();
  const { data } = useQuery(GET_SESSION_LOGS, {
    variables: {
      id: sessionId
    }
  });

  const columns = [
    {
      title: 'Force',
      dataIndex: 'force'
    },
    {
      title: 'Laps',
      dataIndex: 'laps'
    },
    {
      title: 'Time Laps',
      dataIndex: 'timeLaps'
    },
    {
      title: 'Interval',
      dataIndex: 'interval'
    },
    {
      title: 'X',
      dataIndex: 'x'
    },
    {
      title: 'Y',
      dataIndex: 'y'
    },
    {
      title: 'Z',
      dataIndex: 'z'
    }
  ];

  const rows = !data || !data.getLogSessionBySession || !data.getLogSessionBySession.log ? [] :
    data.getLogSessionBySession.log.map((log, index) => ({
      key: index,
      ...log
    }));

  return (
    <Modal
      title={t("LOGS")}
      visible={true}
      onCancel={onClose}
      onOk={onClose}
      width={600}
    >
      <Table 
        size="large" 
        style={{width: 500}} 
        scroll={{ y: 500 }} 
        pagination={false} 
        dataSource={rows} 
        columns={columns} 
      />
    </Modal>
  );
};

export default ShowLogs;