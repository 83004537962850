import React, { useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Item } = Menu;

const DropDown = ({
  items,
  onItemClicked,
  initialState,
  border,
  color,
  fontWeight,
  display,
  flexDirection,
  justifyContent,
  title,
  disabled,
  translate
}) => {
  const [labelClicked, setLabelClicked] = useState(initialState);
  const { t } = useTranslation('dropDown');
  const handleItemClicked = (label) => {
    setLabelClicked(label);
    onItemClicked(label);
  };

  const dropDownContent = () => (
    items.map((item, index) => (
      <Item key={index}>
        <a onClick={() => handleItemClicked(item)}>
          <div className="tableValue">{translate ? t(`${item}`) : item}</div>
        </a>
      </Item>
    ))
  );

  const dropDownMenu = () => <Menu>{dropDownContent(t)}</Menu>;

  const buttonStyle = {
    width: "100%",
    border: border,
    boxShadow: "none"
  };

  const divStyle = {
    title: title,
    display: display,
    color: disabled ? "a9a9a9" : color,
    fontWeight: fontWeight,
    flexDirection: flexDirection,
    justifyContent: justifyContent
  };

  const text = !labelClicked ? initialState : labelClicked;
  return (
    <React.Fragment>
      <div className="title">{title}</div>
      <div className="dropDownContainer">
        <Dropdown overlay={dropDownMenu} disabled={disabled}>
          <Button style={buttonStyle}>
            <div style={divStyle}>

              {translate ? t(`${text}`) : text}
              <div className="downOutlined">
                <DownOutlined />
              </div>
            </div>
          </Button>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

DropDown.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  onItemClicked: PropTypes.func,
  initialState: PropTypes.string,
  border: PropTypes.string,
  boxShadow: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string,
  fontWeight: PropTypes.number,
  flexDirection: PropTypes.string,
  justifyContent: PropTypes.string,
  disabled: PropTypes.bool,
  translate: PropTypes.bool
};

DropDown.defaultProps = {
  title: "",
  items: [],
  onItemClicked: () => {},
  initialState: "...",
  border: "1",
  boxShadow: "none",
  color: "#06122A",
  fontWeight: 600,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  disabled: false,
  translate: true,
};

export default DropDown;
