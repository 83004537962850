import React, { useState } from "react";
import { Col } from "antd";
import { useMutation } from "@apollo/client";
import Input from "../../Components/Input";
import "../../UI/CommonStyles.less";
import { CREATE_COMPANY } from "./Requests";
import EditContainer from "../../Components/EditContainer";
import { useTranslation } from "react-i18next";
import Picture from "../../Components/Picture";

const CreateCompany = ({ onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [logo, setLogo] = useState(undefined);
  const [createCompany] = useMutation(CREATE_COMPANY);

  const checkIfHasChanged = () => {
    if (name) return true;
    return false;
  };

  const handleCreateCompany = async () => {
    await createCompany({
      variables: {
        name,
        logo
      },
      update: (cache, res) => {
        if (res) onClose();
      }
    });
  };
  
  return (
    <EditContainer
      onSave={handleCreateCompany}
      onClose={onClose}
      title={t("Create Company")}
      saveDisabled={!checkIfHasChanged()}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        <Picture
          onChange={(e) => setLogo(e)}
          onDelete={() => setLogo(undefined)}
        />
      </Col>
      <Col span={11}>
        <Input
          title={t("input:name.label")}
          onChange={setName}
          placeholder={t("input:name.placeholder")}
        />
      </Col>
    </EditContainer>
  );
};

export default CreateCompany;
