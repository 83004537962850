import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({ setDaily, setTitle }) => {

  const { t } = useTranslation();
  const dailyRule = ["all", "daily", "total"];

  const filterDaily = (status) => {
    if (status === "all") 
      setDaily(undefined);
    else if (status === "daily") 
      setDaily(true);
    else 
      setDaily(false);
  }

  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:filterTitle.label")}
            placeholder={t("input:filterTitle.placeholder")}
            onChange={(text) => setTitle(text)}
            debounceTimeout={500}
          />
        </Col>
        <Col span={6}>
          <DropDown
            title={t("dropDown:Daily")}
            onItemClicked={filterDaily}
            items={dailyRule}
            initialState="all"
             
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
