import { gql } from "@apollo/client";

export const GET_CHALLENGES = gql`
  query getAllChallenges(
    $page: Int
    $number: Int
    $title: String
    $status: String
    $usersCompany: ID
  ) {
    getAllChallenges(
      infos: {
        page: $page
        number: $number
        title: $title
        status: $status
        usersCompany: $usersCompany
      }
    ) {
      id
      title
      fullScreenImage
      miniatureImage
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      status
      beginDate
      endDate
      rules {
        calories
        laps
      }
      progress
    }
  }
`;

export const GET_CHALLENGE = gql`
  query getChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      fullScreenImage
      miniatureImage
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      status
      beginDate
      endDate
      rules {
        calories
        globalCalcul
        laps
      }
      usersCompany
    }
  }
`;

export const DELETE_CHALLENGES = gql`
  mutation deleteMultipleChallenge($ids: [ID!]!) {
    deleteMultipleChallenge(ids: $ids)
  }
`;


export const UPDATE_CHALLENGE = gql`
  mutation updateChallenge(
    $id: ID!
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $beginDate: Date
    $endDate: Date
    $globalCalcul: Boolean
    $laps: Int
    $calories: Int
    $fullScreenImage: String
    $miniatureImage: String
  ) {
    updateChallenge(
      id: $id
      infos: {
        globalCalcul: $globalCalcul
        laps: $laps
        calories: $calories
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        beginDate: $beginDate
        endDate: $endDate
        fullScreenImage: $fullScreenImage
        miniatureImage: $miniatureImage
      }
    ) {
      id
      fullScreenImage
      miniatureImage
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      status
      beginDate
      endDate
      rules {
        laps
        calories
        globalCalcul
      }
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  mutation createChallenge(
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $beginDate: Date
    $endDate: Date
    $globalCalcul: Boolean
    $calories: Int
    $laps: Int
    $usersCompany: ID
    $fullScreenImage: String
    $miniatureImage: String
  ) {
    createChallenge(
      infos: {
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        beginDate: $beginDate
        endDate: $endDate
        globalCalcul: $globalCalcul
        calories: $calories
        laps: $laps
        usersCompany: $usersCompany
        fullScreenImage: $fullScreenImage
        miniatureImage: $miniatureImage
      }
    ) {
      id
      fullScreenImage
      miniatureImage
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      beginDate
      endDate
    }
  }
`;
