export const launchCreationBadge = async (onClose, refetch, infos, setInfos, createBadge) => {
  try {
      const { title, subtitle, calories, laps, daily, image } = infos;
      createBadge({
        variables: {
          titleEN: title?.en ? title?.en : undefined,
          titleFr: title?.fr ? title?.fr : undefined,
          subEN: subtitle && subtitle.en ? subtitle.en : undefined,
          subFr: subtitle && subtitle.fr ? subtitle.fr : undefined,
          calories: calories && calories > 0 ? calories : undefined,
          laps: laps && laps > 0 ? laps : undefined,
          daily,
          image: image?.indexOf('base64') !== -1  ? image : undefined
        },
        update: (cache, res) => {
          setInfos(undefined);
          onClose();
          refetch();
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};