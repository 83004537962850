import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import ButtonCustom from "../ButtonCustom";
import DropDown from "../../Components/DropDown";
import { useTranslation } from "react-i18next";

const Footer = ({ setPage, page, nextDisable, setNumber }) => {
  const { t } = useTranslation();
  const handleNumberChange = (number) => {
    const parsedNumber = parseInt(number);
    setNumber(parsedNumber);
  };

  return (
    <Row className="footerContainer">
      <Col className="nextPrev" span={4}>
        <ButtonCustom
          title={t("button:PREVIOUS")}
          className="buttonCreate"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        />
      </Col>

      <Col className="nextPrev" span={4}>
        <ButtonCustom
          title={t("button:NEXT")}
          className="buttonCreate"
          disabled={nextDisable}
          onClick={() => setPage(page + 1)}
        />
      </Col>
      <Col span={14} className="fullRight">
        <DropDown
          title={t("dropDown:Number per page")}
          border="0"
          color="#FFA39E"
          onItemClicked={handleNumberChange}
          items={["20", "50", "100"]}
          initialState="20"
          translate={false}
        />
      </Col>
    </Row>
  );
};

Footer.propTypes = {
  setNumber: PropTypes.func,
  setPage: PropTypes.func,
  page: PropTypes.number,
  nextDisable: PropTypes.bool
};

Footer.defaultProps = {
  setNumber: () => {},
  setPage: () => {},
  page: 1,
  nextDisable: false
};

export default Footer;
