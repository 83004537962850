export const launchUpdateCompany = ( infos, updateCompany, id, onClose ) => {
    const { name, logo } = infos;

    try {
      updateCompany({
        variables: {
          id,
          name,
          logo: logo?.indexOf('base64') !== -1  ? logo : undefined
        },
        update: (cache, res) => {}
      });
      onClose();
    } catch (error) {
      console.log("error", error);
    }
};