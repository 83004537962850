import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {

    const getStatus = (status) => {
    if (status === 'onGoing')
      return (<div className="buttonOnGoing">{t("button:OnGoing")}</div>)
    else if (status === 'pending')
      return (<div className="buttonPending">{t("button:Pending")}</div>)
    else
      return (<div className="buttonDone">{t("button:Close")}</div>)
    };

    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: 'id',
      render: id => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("NAME")}</div>,
      dataIndex: 'name',
      render: (name) => <div className="tableValue">{name}</div>
    },
    {
      title: <div className="tableHeading">{t("CREATIONDATE")}</div>,
      dataIndex: 'creationDate',
      render: date => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("BEGINDATE")}</div>,
      dataIndex: 'beginDate',
      render: date => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("ENDDATE")}</div>,
      dataIndex: 'endDate',
      render: date => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("STATUS")}</div>,
      dataIndex: 'status',
      render: status => getStatus(status)
    }
  ];

  return columns;
}


export const getContentRows = (data, t) => {
  return ( 
   !data || !(data.getAllRooms) ? [] : data.getAllRooms.map(({
      id,
      name,
      status,
      beginDate,
      endDate,
      creationDate,
      activity,
      admin
    }) => ({
      key: id,
      id,
      name,
      status,
      beginDate,
      creationDate,
      endDate,
      activity,
      admin
    }))
  )
}


export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllRooms || 
      data.getAllRooms.length === 0 ||
      data.getAllRooms.length < number
    ) return true;
  return false;
}

