import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import DatePickerCustom from "../../Components/DatePickerCustom";
import InputNumber from "../../Components/InputNumber";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { UPDATE_ACTIVITY_IMAGE, CREATE_ACTIVITY } from "./Requests";
import { useMutation } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import Picture from "../../Components/Picture";
import DropDown from "../../Components/DropDown";
import { useTranslation } from "react-i18next";
import Rules from "../../Components/Rules";
import {
  launchCreationActivity,
  handleDropdownChange
} from "./Utility/CreateActivityUtility";
import moment from "moment";

const dateFormat = "DD-MM-YYYY";

const CreateActivity = ({ onClose, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [createActivity] = useMutation(CREATE_ACTIVITY);
  const [updateActivityImage] = useMutation(UPDATE_ACTIVITY_IMAGE);
  const [images, setImages] = useState([]);

  const activityRules = ["free", "calories", "time", "laps"];
  const activityType = [
    "is single player",
    "is multiplayer",
    "can be multiplayer"
  ];
  const categoryType = ["food", "locality", "meditation"];

  const checkIfHasChanged = () => {
    if (infos?.title?.en || infos?.title?.fr) return true;
    return false;
  };

  const handleChangeRuleType = (type, value) => {
    const newInfos = { ...infos };
    if (type === "free") {
      newInfos.calories = undefined;
      newInfos.laps = undefined;
      newInfos.time = undefined;
    } else if (type === "calories") {
      newInfos.calories = value;
      newInfos.laps = undefined;
      newInfos.time = undefined;
    } else if (type === "laps") {
      newInfos.calories = undefined;
      newInfos.laps = value;
      newInfos.time = undefined;
    } else if (type === "time") {
      newInfos.calories = undefined;
      newInfos.laps = undefined;
      newInfos.time = value;
    }

    setInfos(newInfos);
  };

  const hangleChangeRuleValue = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const manageImages = (image, toDelete) => {
    const newImages = [...images];
    if (toDelete) {
      const index = newImages.findIndex((e) => e === image);
      if (index > -1) newImages.splice(index, 1);
    } else {
      newImages.push(image);
    }
    setImages(newImages);
  };

  return (
    <EditContainer
      onSave={() =>
        launchCreationActivity(
          onClose,
          refetch,
          infos,
          setInfos,
          createActivity,
          images,
          updateActivityImage
        )
      }
      title={t("Create Activity")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        {images.map((image, index) => (
          <Picture onDelete={() => manageImages(image, true)} picture={image} />
        ))}
        <Picture
          dontShow={true}
          onDelete={(e) => manageImages(e, true)}
          onChange={(e) => manageImages(e, false)}
        />
      </Col>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <DropDown
            title={t("dropDown:Multi players options")}
            onItemClicked={(value) =>
              handleDropdownChange(value, infos, setInfos)
            }
            items={activityType}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <Input
            title={t("input:worldId.label")}
            placeholder={t("input:worldId.placeholder")}
            onChange={(value) => handleChange("world", value, infos, setInfos)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <InputNumber
            title={t("input:unitiyPassId.label")}
            placeholder={t("input:unitiyPassId.placeholder")}
            onChange={(e) => handleChange("unityPassId", e)}
          />
        </Col>
      </Row>
      <Rules
        updateRuleValue={hangleChangeRuleValue}
        updateRuleType={handleChangeRuleType}
        list={activityRules}
        showFree={true}
      />

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <DropDown
            title={t("dropDown:Category")}
            onItemClicked={(value) => handleChange("category", value)}
            items={categoryType}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            onChange={(e) => handleChange("beginDate", e)}
            value={moment("01-01-2022", dateFormat)}
          />
        </Col>

        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            onChange={(e) => handleChange("endDate", e)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("TITLE")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title="en"
            onChange={(value) => beforeSetText("title", "en", value)}
          />
        </Col>
        <Col span={11}>
          <Input
            title="fr"
            onChange={(value) => beforeSetText("title", "fr", value)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <TextAreaInput
            title="en"
            rows={4}
            onChange={(value) => beforeSetText("subtitle", "en", value)}
          />
        </Col>
        <Col span={11}>
          <TextAreaInput
            title="fr"
            rows={4}
            onChange={(value) => beforeSetText("subtitle", "fr", value)}
          />
        </Col>
      </Row>
    </EditContainer>
  );
};

export default CreateActivity;
