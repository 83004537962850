import React from "react";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";

const InputNumber = ({
  label,
  onChange,
  password,
  width,
  value,
  title,
  disabled,
  placeholder
}) => {

  return (
    <div className="verticalContainer">
      <div className="title">{title}</div>
      <DebounceInput
        onKeyPress={(event) => {
          if (!/[0-9+]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={value}
        className="boxContainer"
        debounceTimeout={500}
        onChange={(event) => onChange(parseInt(event.target.value))}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

InputNumber.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  password: PropTypes.bool,
  width: PropTypes.number,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

InputNumber.defaultProps = {
  title: "",
  label: "InputNumber label...",
  onChange: () => {},
  password: false,
  // value: ,
  disabled: false,
  placeholder: ""
};

export default InputNumber;
