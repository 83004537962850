import React, { useEffect, useMemo, useReducer, createContext } from 'react';
import './App.less';
import { ApolloProvider } from '@apollo/client';
import client from './ApolloClient.js';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';
import Navigators from '../../Navigators';

const AuthContext = createContext();

const App = () => {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null
          };
        default:
          throw new Error("Unknown reducer action: " + action.type);
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null
    }
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      try {
        userToken = localStorage.getItem('userToken');
      } catch (e) {
        console.log('error restoring token', e);
      }
      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async token => {
        localStorage.setItem('userToken', token);
        dispatch({ type: 'SIGN_IN', token });
      },
      signOut: async token => {
        localStorage.removeItem('userToken');
        client.cache.reset();
        dispatch({ type: 'SIGN_OUT' });
      }
    }),
    []
  );
  
  if (state.isLoading)
    return <div>Loading...</div>;
  return (
    <ApolloProvider client={client}>
      <AuthContext.Provider value={authContext}>
        <BrowserRouter>
          <Layout className="layoutStyle">
            <Layout>
              <Navigators  AuthContext={AuthContext} state={state}/>
            </Layout>
          </Layout>
        </BrowserRouter>
      </AuthContext.Provider>
    </ApolloProvider>
  );
};

export default App;