import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import InputNumber from "../../Components/InputNumber";
import DatePickerCustom from "../../Components/DatePickerCustom";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_DEVICE } from "../Devices/Requests";
import "../../UI/CommonStyles.less";
import EditContainer from "../../Components/EditContainer";
import { useTranslation } from "react-i18next";

const EditDevice = ({ onClose, id }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const { data } = useQuery(GET_DEVICE, {
    variables: {
      id
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (data?.getDeviceById) setInfos({ ...data.getDeviceById });
  }, [data]);

  return infos ? (
    <EditContainer
      title={t("Edit Device")}
      onClose={onClose}
      saveDisabled={true}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:User ID")}
            value={infos.user}
            disabled={true}
          />
        </Col>

        <Col span={11}>
          <InputNumber
            title={t("input:Laps")}
            value={infos.laps ? infos.laps : undefined}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <Input
            title={t("input:Machine")}
            value={infos.machine}
            disabled={true}
          />
        </Col>

        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Last Connection")}
            value={
              infos.lastConnection ? moment(infos.lastConnection) : undefined
            }
            disabled={true}
          />
        </Col>
      </Row>
    </EditContainer>
  ) : null;
};

export default EditDevice;
