import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Layout, Table } from "antd";
import "../../UI/CommonStyles.less";
import { GET_BADGES, DELETE_BADGES } from "./Requests";
import Footer from "../../Components/Footer";
import Actions from "../../Components/Actions";
import Header from "../../Components/Header";
import EditBadge from "./EditBadge";
import { 
  getContentRows, 
  getContentColumns, 
  checkIfNextDisabled 
} from "./Utility/TableUtility";
import CreateBadge from "./CreateBadge";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Badges = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [createModal, setCreateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [title, setTitle] = useState(null);
  const [daily, setDaily] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteMultipleBadges] = useMutation(DELETE_BADGES);
  const { data, refetch } = useQuery(GET_BADGES, {
    variables: {
      number,
      page,
      title,
      daily,
    },
    fetchPolicy: "network-only"
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record, index) => {
    return {
      onClick: (event) => {
        setIdClicked(record.id);
      }
    };
  }

  const handleDelete = async () => {
    await deleteMultipleBadges({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDelete();
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditBadge
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          id={idClicked}
        />
      )}
      {createModal && (
        <CreateBadge 
          refetch={refetch} 
          onClose={() => setCreateModal(false)} 
        />
      )}

      <Header name={t("BADGES")}>
        <Filters 
          setDaily={setDaily} 
          setTitle={setTitle} 
        />
      </Header>

      <Content className="card">
        <Actions
          add={() => setCreateModal(true)}
          deletePicked={() => setDeleteModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              setPage={setPage}
              setNumber={setNumber}
              page={page}
              number={number}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Badges;
