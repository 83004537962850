export const handleUpdateRoom = async (id, infos, updateRoom) => {
     try {
      await updateRoom({
        variables: {
          id: id,
          name: infos.name,
          status: infos.status
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};