import React, { useState } from "react";
import { Layout, Table } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import EditUser from "./EditUser";
import { GET_USERS, DELETE_USERS } from "./Requests";
import Header from "../../Components/Header";
import "../../UI/CommonStyles.less";
import Actions from "../../Components/Actions";
import Footer from "../../Components/Footer";
import CreateUser from "./CreateUser";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled
} from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Users = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(20);
  const [page, setPage] = useState(1);
  const [company, setCompany] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [createModal, setCreateModal] = useState(false);
  const [deleteUsers] = useMutation(DELETE_USERS);
  const { data, refetch } = useQuery(GET_USERS, {
    variables: {
      number,
      page,
      company,
      email,
      name
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const handleDeleteUsers = async () => {
    await deleteUsers({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };
  
  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDeleteUsers();
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditUser
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          userId={idClicked}
        />
      )}
      {createModal && (
        <CreateUser
          onClose={() => {
            setCreateModal(false);
          }}
          refetch={refetch}
        />
      )}

      <Header name={t("USERS")}>
        <Filters
          setName={setName}
          setEmail={setEmail}
          setCompany={setCompany}
        />
      </Header>

      <Content className="card">
        <Actions
          deletePicked={() => setDeleteModal(true)}
          add={() => setCreateModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              page={page}
              setPage={setPage}
              initialState={number.toString()}
              setNumber={setNumber}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Users;
