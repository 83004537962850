export const launchCreationActivity = async (
    onClose, refetch, infos, setInfos, 
    createActivity, images, updateActivityImage
  ) => {
  try {
    const { title, subtitle, unityPassId, calories, laps, ...fields } = infos;
    await createActivity({
      variables: {
        titleEN: title?.en ? title?.en : undefined,
        titleFr: title?.fr ? title?.fr : undefined,
        subEN: subtitle && subtitle?.en ? subtitle?.en : undefined,
        subFr: subtitle && subtitle?.fr ? subtitle?.fr : undefined,
        unityPassId: unityPassId > 0 ? unityPassId : undefined,
        calories: calories && calories > 0 ? calories : undefined,
        laps: laps && laps > 0 ? laps : undefined,
        ...fields
      },
      update: (cache, res) => {
        if (res && res.data)
          for (let i = 0; i < images.length; i++) {
            if (images[i])
              handleUpdateImageMutation(images[i], res.data.createActivity.id, updateActivityImage);
          }
        setInfos(undefined);
        onClose();
        refetch();
      }
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const handleUpdateImageMutation = async (image, id, updateActivityImage) => {
  try {
    await updateActivityImage({
      variables: {
        image: image,
        id: id
      }
    });
  } catch (error) {
    console.log("error", error);
  }
};

export const handleDropdownChange = (value, infos, setInfos) => {
    const newInfos = { ...infos };
    if (value === "is single player") {
      newInfos.canChoosePlayers = undefined;
      newInfos.canBeMultiPlayer = false;
    } else if (value === "is multiplayer") {
      newInfos.canChoosePlayers = undefined;
      newInfos.canBeMultiPlayer = true;
    } else if (value === "can be multiplayer") {
      newInfos.canChoosePlayers = true;
      newInfos.canBeMultiPlayer = true;
    }
    setInfos(newInfos);
  };
