import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import InputNumber from "../../Components/InputNumber";
import CheckboxCustom from "../../Components/CheckboxCustom";
import EditContainer from "../../Components/EditContainer";
import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { UPDATE_PASSWORD, UPDATE_USER, GET_USER } from "./Requests";
import DropDown from "../../Components/DropDown";
import { GET_ACCOUNTPLANS } from "../AccountPlans/Requests";
import "../../UI/CommonStyles.less";
import Picture from "../../Components/Picture";
import { GET_COMPANIES } from "../Companies/Requests";
import ButtonCustom from "../../Components/ButtonCustom";
import Friends from "./Friends";
import { useTranslation } from "react-i18next";
import {
  launchUpdateUser,
  changePassword,
  } from "./Utility/EditUserUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditUser = ({ userId, onClose, history, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [password, setPassword] = useState(undefined); 
  const [updateUser] = useMutation(UPDATE_USER);
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const getAccountPlans = useQuery(GET_ACCOUNTPLANS);
  const [plans, setPlans] = useState([]);
  const companiesRequest = useQuery(GET_COMPANIES, {
    fetchPolicy: "network-only"
  });
  const companies = companiesRequest?.data?.getAllCompanies ? companiesRequest.data.getAllCompanies : [];
  const { data, loading, error } = useQuery(GET_USER, {
    variables: {
      id: userId
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (getAccountPlans?.data?.getAllAccountPlans) {
      setPlans(getAccountPlans.data.getAllAccountPlans);
    }
  }, [getAccountPlans.data]);

  useEffect(() => {
    if (data && data.getUserById)
      setInfos(data.getUserById);
  }, [data])


  const plansData = plans.map((item) => {
    return item.name;
  });
  const companiesData = companies.map((item) => {
    return item.name;
  });
  companiesData.unshift("...");


  const beforeSetPlan = (name) => {
    const plan = plans.find((e) => e.name === name);
    if (plan) handleChange("plan",plan.id);
  };


  const beforeSetCompany = (name) => {
    if (name === "...") {
      handleChange("company","...");
      return;
    }
    const company = companies.find((e) => e.name === name);
    if (company) {
      handleChange("company",company.id);
    }
  };


  const getAccountName = (id) => {
    const plan = plans.find((e) => e.id === id);
    if (plan) return plan.name;
  };


  const getCompany = (id) => {
    const company = companies.find((e) => e.id === id);
    if (company) {
      return company.name;
    }
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getUserById });
    let result = false;
    if (Object.keys(modifiedInfos).length > 0)
      result = await launchUpdateUser(modifiedInfos, updateUser, userId);
    if (result) {
      setInfos(undefined);
      refetch();
      onClose();
    }
  }

  if (loading ||  !companies || !data || !data.getUserById || plans.length === 0 || companiesRequest.loading)
    return "Loading...";
  if (error) return "Can't get this user informations";

  return infos ? (
      <EditContainer
        title={t("Edit User")}
        onSave={beforeUpdate}
        onClose={onClose}
        saveDisabled={!checkIfHasChanged(infos, { ...data.getUserById })}
      >

        <Row>
          <ButtonCustom
            title={t("button:ROOMS")}
            type="primary"
            className="buttonWidth"
            onClick={() =>
              history.push({ pathname: "/rooms", state: { userId } })
            }
          />
          <ButtonCustom
            title={t("button:SESSIONS")}
            type="primary"
            className="buttonPath"
            onClick={() =>
              history.push({ pathname: "/sessions", state: { userId } })
            }
          />
          <ButtonCustom
            title={t("button:DEVICES")}
            type="primary"
            className="buttonPath"
            onClick={() =>
              history.push({ pathname: "/devices", state: { userId } })
            }
          />
        </Row>

        <Col className="uploadUserPicture">
          <div className="editPicture">{t("PICTURE")}</div>
          <Picture
            disableDelete={true}
            picture={infos.image}
            onChange={(e) => handleChange("image", e)}
          />
        </Col>
        <Row className="horizontalRowStyle">
          <Col span={11}>
            <Input
              title={t("input:firstName.label")}
              onChange={(e) => handleChange("firstName", e)}
              value={infos.profile.firstName}
              placeholder={t("input:firstName.placeholder")}
            />
          </Col>
          <Col span={11}>
          <Input
              title={t("input:name.label")}
              onChange={(e) => handleChange("lastName", e)}
              value={infos.profile.lastName}
              placeholder={t("input:name.placeholder")}
              />
          </Col>
        </Row>
        <Row className="horizontalRowStyle">
          <Col span={11}>
            <Input
              title={t("input:email.label")}
              onChange={(e) => handleChange("email", e)}
              value={infos.email}
              debounceTimeout={500}
              placeholder={t("input:email.placeholder")}
            />
          </Col>

          <Col span={11}>
            <Row className="horizontalRow">
              <Col span={7}>
                <Input
                  value={infos.phone ? infos.phone.countryCode : ""}
                  placeholder={t("input:code.placeholder")}
                  title={t("input:code.label")}
                  onChange={(e) => handleChange("countryCode", e.toString())}
                />
              </Col>

              <Col span={15}>
                <InputNumber
                  title={t("input:number.label")}
                  value={
                    infos.phone && !!parseInt(infos.phone.number)
                      ? parseInt(infos.phone.number)
                      : undefined
                  }
                  placeholder={t("input:number.placeholder")}
                  onChange={(e) => handleChange("number", e.toString())}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="horizontalRowStyle">
          <Col span={11}>
            <DropDown
              title={t("dropDown:Plan")}
              items={plansData}
              onItemClicked={(name) => beforeSetPlan(name)}
              initialState={getAccountName(infos.plan)}
              translate={false}
            />
          </Col>
          <Col span={11} className="fullRight">
            <CheckboxCustom
              title={t("checkbox:adminEveia")}
              onChange={(e) => handleChange("admin", e)}
              defaultChecked={infos.admin}
            />
          </Col>
        </Row>

        <Row className="horizontalRowStyle">
          <Col span={11}>
            <DropDown
              title={t("dropDown:Company")}
              items={companiesData}
              onItemClicked={(name) => beforeSetCompany(name)}
              initialState={getCompany(infos.company)}
              translate={false}
            />
          </Col>
          <Col span={11} className="fullRight">
            <CheckboxCustom
              title={t("checkbox:adminCompany")}
              defaultChecked={infos?.adminCompany && infos?.company !== null}
              onChange={(e) => handleChange("adminCompany", e)}
              disabled={infos?.company ? infos?.company === "..." : infos?.company === null}
            />
          </Col>
        </Row>
        <Row className="horizontalRowStyle">
          <Col span={11}>
            <Input
              title={t("input:password.label")}
              password={false}
              onChange={(e) => setPassword(e)}
              placeholder={t("input:password.placeholder")}
            />
          </Col>
          <Col span={11}>
            <ButtonCustom
              title={t("button:CHANGE PASSWORD")}
              type="primary"
              className="passwordButton"
              disabled={!password}
              onClick={() => changePassword(password, updatePassword, userId)}
            />
          </Col>
        </Row>
        
        <Friends userId={userId} />
      </EditContainer>
  ) : null;
};

EditUser.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  history: PropTypes.object.isRequired
};

EditUser.defaultProps = {
  onClose: () => {}
};

export default EditUser;
