import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({
  idFilter,
  setIdFilter,
  setActivityId,
  handleNumberOfSessionsChange,
  setRoomId,
  setStatus
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={9}>
          <Input
            title={t("input:filterUserId.label")}
            placeholder={t("input:filterUserId.placeholder")}
            value={idFilter}
            onChange={setIdFilter}
            debounceTimeout={500}
          />
        </Col>
        <Col span={8}>
          <Input
            title={t("input:filterActivityId.label")}
            placeholder={t("input:filterActivityId.placeholder")}
            onChange={setActivityId}
            debounceTimeout={500}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={9}>
          <Input
            title={t("input:filterRoomId.label")}
            placeholder={t("input:filterRoomId.placeholder")}
            onChange={setRoomId}
            debounceTimeout={500}
          />
        </Col>
        <Col span={8}>
          <DropDown
            title={t("input:filterStatus.label")}
            onItemClicked={(status) => {
              if (status === "...") setStatus(undefined);
              else setStatus(status);
            }}
            items={["...", "on", "off", "done", "pause"]}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
