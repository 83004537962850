import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from 'moment';

const dateFormatList = ["DD-MM-YYYY"];
const DatePickerCustom = ({ onChange, title, value, disabled }) => {
  return (
    <div className="verticalContainer">
      <div className="title">{title}</div>
      <DatePicker
        format={dateFormatList}
        className="boxContainer"
        onChange={(date, dateString) => onChange(date, dateString)}
        defaultValue={value}
        disabled={disabled}
      />
    </div>
  );
};

DatePickerCustom.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.object,
  disabled: PropTypes.bool
};

DatePickerCustom.defaultProps = {
  onChange: () => {},
  title: "",
  disabled: false
};

export default DatePickerCustom;
