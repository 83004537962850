export const launchCreationChallenge = async (onClose, refetch, infos, setInfos, createChallenge, usersCompany) => {
   try {
      const { title, subtitle, calories, laps, beginDate, endDate, ...fields } = infos;
      await createChallenge({
        variables: {
          titleEN: title?.en ? title?.en : undefined,
          titleFr: title?.fr ? title?.fr : undefined,
          subEN: subtitle && subtitle.en ? subtitle.en : undefined,
          subFr: subtitle && subtitle.fr ? subtitle.fr : undefined,
          beginDate: beginDate ? beginDate.toDate() : undefined,
          endDate: endDate ? endDate.toDate() : undefined,
          calories: calories && calories > 0 ? calories : undefined,
          laps: laps && laps > 0 ? laps : undefined,
          usersCompany,
          ...fields
        },
        update: (cache, res) => {
          setInfos(undefined);
          onClose();
          refetch();
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};
