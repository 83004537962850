import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({
  setUserFilter,
  userFilter,
  setActivityFilter,
  setNameFilter,
  setStatusFilter,
  setBeginDate,
  setCreationDate,
  setEndDate
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={15}>
          <Input
            title={t("input:filterUserId.label")}
            placeholder={t("input:filterUserId.placeholder")}
            onChange={setUserFilter}
            debounceTimeout={500}
          />
        </Col>

        <Col span={5}>
          <DropDown
            title={t("dropDown:Status")}
            onItemClicked={(status) => {
              if (status === "all") setStatusFilter(undefined);
              else setStatusFilter(status);
            }}
            items={["all", "pending", "onGoing", "close"]}
            initialState="all"
             
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
