import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import DatePickerCustom from "../../Components/DatePickerCustom";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { UPDATE_CHALLENGE, GET_CHALLENGE } from "./Requests";
import { GET_COMPANIES } from "../Companies/Requests";
import { useMutation, useQuery } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import moment from "moment";
import Picture from "../../Components/Picture";
import { useTranslation } from "react-i18next";
import {
  launchUpdateChallenge,
} from "./Utility/EditChallengeUtility";
import Rules from "../../Components/Rules";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditChallenge = ({ onClose, id, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateChallenge] = useMutation(UPDATE_CHALLENGE);
  const companyQueries = useQuery(GET_COMPANIES, {
    fetchPolicy: "network-only"
  });
  const { data, loading, error } = useQuery(GET_CHALLENGE, {
    variables: {
      id
    }
  });
  const challengeRules = ["calories", "laps"];

  const companiesData = companyQueries?.data?.getAllCompanies
    ? companyQueries.data.getAllCompanies.map((item, index) => {
        return item.name;
      })
    : [];
  companiesData.unshift("...");

  const companies = companyQueries?.data?.getAllCompanies
    ? companyQueries.data.getAllCompanies
    : [];

  useEffect(() => {
    if (data?.getChallenge)
      setInfos({ ...data.getChallenge, ...data.getChallenge.rules });
  }, [data]);

  const getCompany = (id) => {
    const company = companies.find((e) => e.id === id);
    if (company) {
      return company.name;
    }
  };

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const getInitialRule = () => {
    if (!infos) return null;
    if (infos.calories) return { type: "calories", value: infos.calories };
    else if (infos.laps) return { type: "laps", value: infos.laps };
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, { ...data.getChallenge, ...data.getChallenge.rules});
    if (Object.keys(modifiedInfos).length > 0)
      await launchUpdateChallenge(id, modifiedInfos, updateChallenge);
    setInfos(undefined);
    onClose();
    refetch();
  }


  if (loading || !data || !data.getChallenge || companyQueries.loading) return "Loading...";
  if (error) return "Can't get this challenge informations";

  const rule = getInitialRule();

  
  return infos ? (
    <EditContainer
      title={t("Edit Challenge")}
      onSave={beforeUpdate}
      onClose={onClose}
      saveDisabled={
        !checkIfHasChanged(infos, {
          ...data.getChallenge,
          ...data.getChallenge.rules
        })
      }
    >
      <Row>
        <Col className="upload" span={13}>
          <div className="editPicture">{t("MINIATURE IMAGE")}</div>
          <Picture
            picture={infos.miniatureImage}
            disableDelete={true}
            onChange={(e) => handleChange("miniatureImage", e)}
          />
        </Col>
        <Col className="upload" span={11}>
          <div className="editPicture">{t("FULL SCREEN IMAGE")}</div>
          <Picture
            picture={infos.fullScreenImage}
            disableDelete={true}
            onChange={(e) => handleChange("fullScreenImage", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRow">
        <Col span={24}>
          <DropDown
            title="Specific Company"
            disabled={true}
            items={companiesData}
            initialState={
              infos.usersCompany ? getCompany(infos.usersCompany) : "..."
            }
            translate={false}
          />
        </Col>
      </Row>

      <Rules
        dropdownInialState={rule.type}
        inputInitialValue={rule.value}
        list={challengeRules}
        disabled={true}
      />

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            value={infos.beginDate ? moment(infos.beginDate) : undefined}
            onChange={(e) => handleChange("beginDate", e)}
          />
        </Col>
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            value={infos.endDate ? moment(infos.endDate) : undefined}
            onChange={(e) => handleChange("endDate", e)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.titleAll).map((e, index) =>
          index < Object.keys(infos.titleAll).length - 1 ? (
            <Col key={index + "title"} span={11}>
              <Input
                title={e}
                value={infos.titleAll[e]}
                onChange={(value) => beforeSetText("titleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.subtitleAll).map((e, index) =>
          index < Object.keys(infos.subtitleAll).length - 1 ? (
            <Col key={index + "subtitle"} span={11}>
              <TextAreaInput
                title={e}
                rows={4}
                value={infos.subtitleAll[e]}
                onChange={(value) => beforeSetText("subtitleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>
    </EditContainer>
  ) : null;
};

export default EditChallenge;
