import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const { TextArea } = Input;

const TextAreaInput = ({
  label,
  placeholder,
  onChange,
  width,
  value,
  title
}) => {

  return (
    <div className="verticalContainer">
      <div className="title">{title}</div>
      <div>
        <TextArea
          rows={4}
          placeholder={placeholder}
          className="boxContainer"
          onChange={(event) => onChange(event.target.value)}
          value={value}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number,
  value: PropTypes.string,
};

Input.defaultProps = {
  title: "",
  label: "Input label...",
  placeholder: "Placeholder...",
  onChange: () => {},
  value: "",
};

export default TextAreaInput;
