import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import { useMutation } from "@apollo/client";
import { CREATE_ROOM } from "./Requests";
import "../../UI/CommonStyles.less";
import EditContainer from "../../Components/EditContainer";
import DropDown from "../../Components/DropDown";
import ButtonCustom from "../../Components/ButtonCustom";
import { useTranslation } from "react-i18next";

const CreateRoom = ({ onClose, refetch }) => {
  const { t } = useTranslation();
  const [admin, setAdmin] = useState("");
  const [name, setName] = useState("");
  const [activity, setActivity] = useState("");
  const [status, setStatus] = useState("pending");
  const [userList, setUserList] = useState([]);
  const [createRoom] = useMutation(CREATE_ROOM);

  const checkIfHasChanged = () => {
    if (name !== '' && activity !== '' && admin !== '')
      return true;
    return false;
  }

  const addItemToUserList = () => {
    const newUserList = [...userList];
    newUserList.push(undefined);
    setUserList(newUserList);
  }

  const setItemValue = (index, value) => {
    const newUserList = [...userList];
    newUserList[index] = value;
    setUserList(newUserList);
  }

  const handleCreate = async () => {
   try {
      const filteredUserList = userList.filter((e) => e && e !== '')
      createRoom({
        variables: {
          activity,
          admin,
          name,
          status,
          userList: filteredUserList
        },
        update: (cache, res) => {}
      });
      refetch();
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <EditContainer
      onSave={handleCreate}
      title={t("Create Room")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:adminId.label")}
            placeholder={t("input:adminId.placeholder")}
            onChange={(e) => setAdmin(e)}
          />
        </Col>

        <Col span={11}>
          <Input
            title={t("input:activityId.label")}
            placeholder={t("input:activityId.placeholder")}
            onChange={(e) => setActivity(e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            placeholder={t("input:name.placeholder")}
            onChange={(e) => setName(e)}
          />
        </Col>

        <Col span={11}>
          <DropDown
            title={t("dropDown:Status")}
            items={["pending", "onGoing", "close"]}
            initialState={t("dropDown:pending")}
            disabled={true}
            onItemClicked={setStatus}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="titleStyle">{t("User list")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <ButtonCustom 
          title={t("button:add user")} 
          type="primary" 
          className="buttonAddFriend" 
          onClick={addItemToUserList}/>
      </Row>
      
      <React.Fragment>
        <Row className="horizontalRowStyle">
        {
          userList.map((e, index) => (
            <Col span={11} className="userStyle" key={index}>
              <Input
                title={t("input:userId.label")}
                placeholder={t("input:userId.placeholder")}
                value={e}
                onChange={(e) => setItemValue(index, e)}
              />
            </Col>
          ))
        }
        </Row>
      </React.Fragment>
    </EditContainer>
  );
};

export default CreateRoom;
