import { validateEmail, validatePassword } from "../../../Tools/ValidateField";

export const handleCreateUser = async (
  onClose, 
  refetch,
  infos, 
  setInfos, 
  createUser
  ) => {
  try{
      const { 
        admin, firstName, lastName, email, password, 
        countryCode, number, adminCompany, plan, company
      } = infos;
      
      const emailMessage = validateEmail(email);
      if (emailMessage !== "") {
        alert(emailMessage);
        return;
      }
        
      const passwordMessage = validatePassword(password);
      if (passwordMessage !== "") {
        alert(passwordMessage);
        return;
      }
      
      await createUser({
        variables: {
          admin,
          firstName,
          lastName,
          email,
          password,
          countryCode,
          number,
          plan,
          company,
          adminCompany
        },
        update: (cache, res) => {
          if (res)
            onClose();
        }
      });
      setInfos(undefined);
      refetch();
    } catch (error) {
      console.log("error", error);
    }
};