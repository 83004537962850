import React from "react";
import { Row, Col } from "antd";
import CheckboxCustom from "../../Components/CheckboxCustom";
import Input from "../../Components/Input";
import DropDown from "../../Components/DropDown";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({
  setTitle,
  setBeginDate,
  setEndDate,
  setClose,
  handleDropdownChange
}) => {
  const { t } = useTranslation();

  const activityRule = [ "all", "is single player", "is multiplayer",  "can be multiplayer"]
          
  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:filterTitle.label")}
            placeholder={t("input:filterTitle.placeholder")}
            onChange={(text) => setTitle(text)}
            debounceTimeout={500}
          />
        </Col>

        <Col span={6}>
          <DropDown
            title={t("dropDown:Multi players options")}
            onItemClicked={handleDropdownChange}
            items={activityRule}
            initialState="all"
             
          />
        </Col>

        <Col span={3} className="fullRight">
          <CheckboxCustom
            title={t("checkbox:Close")}
            onChange={(e) => setClose(e)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
