import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GET_ME } from "../Pages/Users/Requests";
import { Layout } from "antd";
import MainMenu from "../Components/MainMenu";
import CompanyMenu from "../Components/CompanyMenu";
import AdminNavigator from "./AdminNavigator";
import CompanyNavigator from "./CompanyNavigator";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Navigators = ({ AuthContext, state }) => {
  const { data, error } = useQuery(GET_ME);
  const isAdminCompany =
    data &&
    data.me &&
    !!data.me.adminCompany &&
    !!data.me.company &&
    !data.me.admin; //check if not admin of eveia
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (history.location.pathname.includes("reset-password")) return;
    if (history.location.pathname.includes("mail-confirmation")) return;
    if (error) history.push("/logout");
  }, [error, history]);

  const showMenu = () => {
    if (state.userToken) {
      if (location.pathname !== "/logout" && location.pathname !== "/signin") {
        if (isAdminCompany)
          return (
            <CompanyMenu companyId={data.me.company} isAdminCompany={true} />
          );
        else return <MainMenu isAdminCompany={false} />;
      }
      return true;
    }
    return null;
  };
  return (
    <React.Fragment>
      <Layout>
        {showMenu()}
        <Layout.Content>
          <Layout className="page">
            {isAdminCompany ? (
              <CompanyNavigator AuthContext={AuthContext} state={state} />
            ) : (
              <AdminNavigator AuthContext={AuthContext} state={state} />
            )}
          </Layout>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
};
export default Navigators;
