import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import TextAreaInput from "../../Components/TextAreaInput";
import { useMutation } from "@apollo/client";
import Picture from "../../Components/Picture";
import EditContainer from "../../Components/EditContainer";
import { CREATE_WORLD, UPDATE_IMAGES } from "./Requests";
import { useTranslation } from "react-i18next";
import {
  handleCreateWorld,
  manageImages
} from "./Utility/CreateWorldUtility";

const CreateWorld = ({ onClose, refetch }) => {
  
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [createWorld] = useMutation(CREATE_WORLD);
  const [updateWorldImage] = useMutation(UPDATE_IMAGES);
  const [images, setImages] = useState([]);

  const checkIfHasChanged = () => {
    if ( infos?.title?.en || infos?.title?.fr )
      return true;
    return false;
  };

  const beforeSetText = (type, lang, value) => {
  const newInfos = { ...infos };

  newInfos[type] = { ...newInfos[type], [lang]: value };
  setInfos(newInfos);
};

  return (
    <EditContainer
      onSave={() => handleCreateWorld(
        onClose, refetch, infos, setInfos, 
        createWorld, images, updateWorldImage
      )}
      title={t("Create World")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        {images.map((image, index) => (
          <Picture
            key={index}
            onDelete={() => manageImages(image, true, images, setImages)}
            picture={image}
          />
        ))}
        <Picture
          dontShow={true}
          key={"eqs"}
          onChange={(e) => manageImages(e, false, images, setImages)}
        />
      </Col>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title="en"
            onChange={(value) => beforeSetText("title", "en", value)}
          />
        </Col>
        <Col span={11}>
          <Input
            title="fr"
            onChange={(value) => beforeSetText("title", "fr", value)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <TextAreaInput
            title="en"
            onChange={(value) => beforeSetText("subtitle", "en", value)}
          />
        </Col>
        <Col span={11}>
          <TextAreaInput
            title="fr"
            onChange={(value) => beforeSetText("subtitle", "fr", value)}
          />
        </Col>
      </Row>
    </EditContainer>
  );
};

export default CreateWorld;
