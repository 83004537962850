import { validateEmail, validatePassword, validatePhoneNumber } from "../../../Tools/ValidateField";

export const launchUpdateUser = async (infos, updateUser, userId ) => {
    const {
      countryCode,
      number,
      email,
      ...fields
    } = infos;

    const numberMessage = 
      countryCode !== "" &&
      number !== "" &&
      countryCode !== undefined &&
      number !== undefined
      ? validatePhoneNumber(countryCode + number)
      : "";

    const emailMessage =
      email !== "" && email !== undefined ? validateEmail(email) : "";
    if (numberMessage !== "") {
      alert(numberMessage);
      return;
    } else if (emailMessage !== "") {
      alert(emailMessage);
      return;
    }
    try {
      updateUser({
        variables: {
          id : userId,
          countryCode,
          number,
          email,
          ...fields
        },
        update: (cache, res) => {
        }
      });
    } catch (error) {
      alert(error);
    }
    return true;
  };

export const changePassword = (password, updatePassword, userId) => {
  const passwordMessage =
    password !== undefined && password !== ""
      ? validatePassword(password)
      : "";
  if (passwordMessage) {
    alert(passwordMessage);
    return;
  }
  try {
    updatePassword({
      variables: {
        userId: userId,
        newPassword: password
      },
      update: (cache, res) => {}
    });
  } catch (error) {
    alert(error);
  }
};
