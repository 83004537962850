import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Layout, Table } from "antd";
import { GET_CHALLENGES, DELETE_CHALLENGES } from "../Challenges/Requests";
import { GET_ME } from "../Users/Requests";
import "../../UI/CommonStyles.less";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Actions from "../../Components/Actions";
import EditCompanyChallenge from "./EditCompanyChallenge";
import CreateCompanyChallenge from "./CreateCompanyChallenge";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  HandleDelete,
  checkIfNextDisabled
} from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Challenges = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [statusFilter] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [title] = useState(null);
  const [usersCompany, setUsersCompany] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteMultipleChallenge] = useMutation(DELETE_CHALLENGES);

  const { data, refetch } = useQuery(GET_CHALLENGES, {
    variables: {
      number,
      page,
      title,
      usersCompany,
      status: statusFilter
    },
    fetchPolicy: "network-only"
  });

  const myData = useQuery(GET_ME);

  const companyId = myData?.data?.me?.company;

  useEffect(() => {
    if (companyId) {
      setUsersCompany(companyId);
    }
  }, [companyId]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    HandleDelete(
      deleteMultipleChallenge,
      selectedRowKeys,
      setSelectedRowKeys,
      refetch
    )
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditCompanyChallenge
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          id={idClicked}
        />
      )}

      {createModal && (
        <CreateCompanyChallenge
          refetch={refetch}
          onClose={() => setCreateModal(false)}
          companyId={companyId}
          usersCompany={usersCompany}
        />
      )}

      <Header canShowFilters={false} name={t("CHALLENGES")} />

      <Content className="card">
        <Actions
          add={() => setCreateModal(true)}
          deletePicked={() => setDeleteModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data, t)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              setPage={setPage}
              setNumber={setNumber}
              page={page}
              number={number}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Challenges;
