import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("MACHINE")}</div>,
      dataIndex: "machine",
      render: (machine) => <div className="tableValue">{machine}</div>
    },
    {
      title: <div className="tableHeading">{t("LAST CONNECTION")}</div>,
      dataIndex: "lastConnection",
      render: (date) => (
        <div className="tableValue">{readableDate(date)}</div>
      )
    }
  ];

  return columns;
}

export const getContentRows = (data) => {
  return ( 
    !data || !data.getAllDevices
      ? []
      : data.getAllDevices.map(({ 
        id, user, machine, lastConnection 
      }) => ({
          key: id,
          id,
          user,
          machine,
          lastConnection
        })
    )
  )
}

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllDevices || 
      data.getAllDevices.length === 0 ||
      data.getAllDevices.length < number
    ) return true;
  return false;
}

