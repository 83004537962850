export const launchUpdateAccountPlan = async ( infos, updateAccountPlan, id, onClose ) => {
    try {
      const { duration, ...fields } = infos;
      updateAccountPlan({
        variables: {
          id,
          duration: duration * 60000 * 60 * 24,
          ...fields
        },
        update: (cache, res) => {}
      });
      onClose();
    } catch (error) {
      console.log("error", error);
    }
};




