import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import EditContainer from "../../Components/EditContainer";
import DropDown from "../../Components/DropDown";
import { GET_ROOM, UPDATE_ROOM, UPDATE_ROOM_USER_STATUS } from "./Requests";
import moment from "moment";
import DatePickerCustom from "../../Components/DatePickerCustom";
import { useTranslation } from "react-i18next";
import { handleUpdateRoom } from "./Utility/EditRoomUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditRoom = ({ onClose, id, refetch }) => {

  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateRoom] = useMutation(UPDATE_ROOM);
  const [updateRoomUserStatus] = useMutation(UPDATE_ROOM_USER_STATUS);
  const { data } = useQuery(GET_ROOM, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  });
  const userStatusRule = ['pending', 'ready', 'declined', 'pause', 'on', 'done'];

  useEffect(() => {
    if (data?.getRoom)
      setInfos({...data.getRoom});
  }, [data]);

  // const hasChanged = () => {
  //   const oldInfos = {...data.getRoom};
  //   if (infos.name !== oldInfos.name ||
  //     infos.status !== oldInfos.status)
  //     return true;
  //   return false;
  // };

  const handleChangeUserStatus = (index, value) => {
    const newInfos = {...infos};
    newInfos.list[index].status = value;
    setInfos(newInfos);
    handleRoomUserStatus(value, newInfos.list[index].user.id);
  };

  const handleRoomUserStatus = async (status, userId) => {
    try {
      await updateRoomUserStatus({
        variables: {
          id,
          status,
          userId
        }
      });
      refetch();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getRoom});
    if (Object.keys(modifiedInfos).length > 0)
      await handleUpdateRoom(id, modifiedInfos, updateRoom);
    setInfos(undefined);
    onClose();
    refetch();
  };

  return infos ? (
    <EditContainer
      title={t("Edit Room")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged(infos, {...data.getRoom})}
      onSave={beforeUpdate}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            placeholder={t("input:name.placeholder")}
            value={infos.name}
            onChange={(e) => handleChange('name', e)}
          />
        </Col>

        <Col span={11}>
          <DropDown
            title={t("dropDown:Status")}
            items={["pending", "onGoing", "close"]}
            initialState={infos.status}
            onItemClicked={(e) => handleChange('status', e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <Input
            title={t("input:adminId.label")}
            placeholder={t("input:adminId.placeholder")}
            value={infos.admin.id}
            disabled={true}
          />
        </Col>

        <Col span={11}>
          <Input
            title={t("input:activityId.label")}
            placeholder={t("input:activityId.placeholder")}
            value={infos.activity.id}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
            <DatePickerCustom
              title={t("dropDown:Creation date")}
              disabled={true}
              value={infos.creationDate ? moment(infos.creationDate) : undefined}
            />
        </Col>
        <Col span={11}>
            <DatePickerCustom
              title={t("dropDown:Begin date")}
              disabled={true}
              value={infos.beginDate ? moment(infos.beginDate) : undefined}
            />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
       <Col span={11}>
            <DatePickerCustom
              title={t("dropDown:End date")}
              disabled={true}
              value={infos.endDate ? moment(infos.endDate) : undefined}
            />
        </Col>
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("User list")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {
          infos.list.map((e, index) => (
            <React.Fragment key={e.user.id}>
              <Col span={11}>
                <Input
                  title={t("input:userId.label")}
                  value={e.user.id}
                  disabled={true}
                />
              </Col>

              <Col span={11}>
              <DropDown
                title={t("dropDown:User status")}
                items={userStatusRule}
                initialState={e.status}
                onItemClicked={(e) => handleChangeUserStatus(index, e)}
              />
              </Col>
            </React.Fragment>
          ))
        }
      </Row>
    </EditContainer>
  ) : null;
};

export default EditRoom;
