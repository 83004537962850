import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import "./CopyIdStyle.less";

const CopyId = ({ id }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div>
      <CopyToClipboard text={id}>
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={(event) => event.stopPropagation()}
          className="IdCopyContainer"
        >
          <React.Fragment>
            <span className="id">
              {id.substring(0, 5) + "..." + id.substr(id.length - 5)}
            </span>
            {hovered && <CopyOutlined className="copyOutlined" />}
          </React.Fragment>
        </div>
      </CopyToClipboard>
    </div>
  );
};

CopyId.propTypes = {
  id: PropTypes.string.isRequired
};

export default CopyId;
