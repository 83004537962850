import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
     return auth ?
        <Component {...props} />
        : <Redirect
            to={{
              pathname: '/signin'
            }}
          />
      }
    }
  />
);

export default AuthenticatedRoute;

