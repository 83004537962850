import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import CheckboxCustom from "../../Components/CheckboxCustom";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { UPDATE_BADGE, GET_BADGE } from "./Requests";
import { useMutation, useQuery } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import Picture from "../../Components/Picture";
import DropDown from "../../Components/DropDown";
import { useTranslation } from "react-i18next";
import Rules from "../../Components/Rules";
import { launchUpdateBadge } from "./Utility/EditBadgeUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditBadge = ({ onClose, id }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateBadge] = useMutation(UPDATE_BADGE);
  const badgeRules = [
    "calories",
    "laps"
  ];
  
  const { data, loading, error, refetch } = useQuery(GET_BADGE, {
    variables: {
      id
    }
  });

  useEffect(() => {
    if (data?.getBadge) setInfos(data.getBadge);
  }, [data]);

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const getInitialRule = () => {
    if (!infos)
      return null;
    if (infos.rules.calories)
      return {type: 'calories', value: infos.rules.calories};
    else if (infos.rules.laps)
      return {type: 'laps', value: infos.rules.laps};
  };

  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getBadge });
    if (Object.keys(modifiedInfos).length > 0)
      await launchUpdateBadge(id, modifiedInfos, updateBadge);
    setInfos(undefined);
    onClose();
    refetch();
  }

  if (loading || !data || !data.getBadge) return "Loading...";
  if (error) return "Can't get this badge informations";

  const rule = getInitialRule();
  return infos ? (
    <EditContainer
      title={t("Edit Badge")}
      onSave={beforeUpdate}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged(infos, {...data.getBadge})}
    >
      <Col className="upload">
        <div className="editPicture">{t("PICTURE")}</div>
        <Picture
          picture={infos.image}
          disableDelete={true}
          onChange={(e) => {
            setInfos({ ...infos, image: e });
          }}
        />
      </Col>

      <Row className="horizontalRowStyle">
        <Col span={24} className="fullRight">
          <CheckboxCustom
            title={t("dropDown:Daily")}
            disabled={true}
            defaultChecked={infos.daily}
          />
        </Col>
      </Row>

      <Rules
        dropdownInialState={rule.type}
        inputInitialValue={rule.value}
        list={badgeRules}
        disabled={true}
      />
      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.titleAll).map((e, index) =>
          index < Object.keys(infos.titleAll).length - 1 ? (
            <Col key={index + "title"} span={11}>
              <Input
                title={e}
                value={infos.titleAll[e]}
                onChange={(value) => beforeSetText("titleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        {Object.keys(infos.subtitleAll).map((e, index) =>
          index < Object.keys(infos.subtitleAll).length - 1 ? (
            <Col key={index + "subtitle"} span={11}>
              <TextAreaInput
                title={e}
                rows={4}
                value={infos.subtitleAll[e]}
                onChange={(value) => beforeSetText("subtitleAll", e, value)}
              />
            </Col>
          ) : null
        )}
      </Row>
    </EditContainer>
  ) : null;
};

export default EditBadge;
