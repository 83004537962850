import React, { useState, useEffect } from "react";
import { Layout, Table } from "antd";
import { useQuery } from "@apollo/client";
import { GET_ME, GET_USERS } from "../Users/Requests";
import Header from "../../Components/Header";
import "../../UI/CommonStyles.less";
import Footer from "../../Components/Footer";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled
} from "./Utility/TableUtility";

const { Content } = Layout;

const Users = ({ history }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState(20);
  const [page, setPage] = useState(1);
  const [company, setCompany] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data } = useQuery(GET_USERS, {
    variables: {
      number: number,
      page: page,
      company,
      email: email,
      name: name
    }
  });

  const myData = useQuery(GET_ME);

  const companyId = myData?.data?.me?.company;

  useEffect(() => {
    if (companyId) {
      setCompany(companyId);
    }
  }, [companyId]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  return (
    <Layout className="pageContainer">
      <Header name={t("USERS")}>
        <Filters setName={setName} setEmail={setEmail} />
      </Header>

      <Content className="card">
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          footer={() => (
            <Footer
              page={page}
              setPage={setPage}
              initialState={number.toString()}
              setNumber={setNumber}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
    </Layout>
  );
};

export default Users;
