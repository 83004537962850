import React, { useState } from "react";
import DropDown from "../../Components/DropDown";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const defaultLanguage = localStorage.getItem("lang");
  const [userLanguage, setUserLanguage] = useState(defaultLanguage ? defaultLanguage : 'fr');

  const changeLanguage = (e) => {
    if (e.toLowerCase() === "fr") {
      i18n.changeLanguage("fr");
      localStorage.setItem("lang", "fr");
      setUserLanguage("fr");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
      setUserLanguage("en");
    }
  };

  return (
    <DropDown
      items={["FR", "EN"]}
      border="0"
      onItemClicked={(e) => changeLanguage(e)}
      initialState={userLanguage.toUpperCase()}
      translate={false}
    />
  );
};

export default LanguageSelector;
