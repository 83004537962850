import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
   const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "copyId",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("LAST CONNECTION")}</div>,
      dataIndex: "lastConnection",
      render: (date) => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("FIRST NAME")}</div>,
      dataIndex: "firstName",
      render: (firstName) => <div className="tableValue">{firstName ? firstName : '...'}</div>
    },
    {
      title: <div className="tableHeading">{t("LAST NAME")}</div>,
      dataIndex: "lastName",
      render: (lastName) => <div className="tableValue">{lastName ? lastName :  '...'}</div>
    },
    {
      title: <div className="tableHeading">{t("EMAIL")}</div>,
      dataIndex: "email",
      render: (email) => <div className="tableValue">{email ? email : '...'}</div>
    },
    {
      title: <div className="tableHeading">{t("VERIFIED")}</div>,
      dataIndex: "verified",
      render: (verified) =>
      <React.Fragment>
        {!verified ? (
          <CloseCircleOutlined className="closeOutlined" />
        ) : (
          <CheckCircleOutlined className="checkOutlined" />
        )}
    </React.Fragment>
    }
  ];

  return columns;
}

export const getContentRows = (data) => {
  return ( 
    !data || !data.getAllUser
      ? []
      : data.getAllUser.map(
          ({
            id,
            profile: { firstName, lastName },
            lastConnection,
            email,
            verified
          }) => ({
            key: id,
            copyId: id,
            id,
            lastConnection,
            firstName,
            lastName,
            email,
            verified
          })
        )
    )
}

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllUser || 
      data.getAllUser.length === 0 ||
      data.getAllUser.length < number
    ) return true;
  return false;
}
