import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "antd";
import "./CheckboxStyle.less";

const CheckboxCustom = ({ onChange, title, defaultChecked, disabled}) => {
  return (
    <div className="checkbox">
      <Checkbox
        onChange={(e) => onChange(e.target.checked)}
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
      <div className="checkboxTitle">{title}</div>
    </div>
  );
};

CheckboxCustom.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool
};

CheckboxCustom.defaultProps = {
  onChange: () => {},
  title: "",
  disabled: false
};

export default CheckboxCustom;
