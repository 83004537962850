import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import DatePickerCustom from "../../Components/DatePickerCustom";
import TextAreaInput from "../../Components/TextAreaInput";
import InputNumber from "../../Components/InputNumber";
import "../../UI/CommonStyles.less";
import { CREATE_CHALLENGE } from "../Challenges/Requests";
import { useMutation } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import Picture from "../../Components/Picture";
import { useTranslation } from "react-i18next";
import {
  launchCreationChallenge
} from "./Utility/CreateCompanyChallengeUtility";
import Rules from "../../Components/Rules";

const CreateAdminChallenge = ({ onClose, history, refetch, companyId }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [createChallenge] = useMutation(CREATE_CHALLENGE);
  const [usersCompany, setUsersCompany] = useState(undefined);
  const challengeRules = [
    "calories",
    "laps"
  ];

  
  useEffect(() => {
    if (companyId) {
      setUsersCompany(companyId);
    }
  }, [companyId]);

  const checkIfHasChanged = () => {
    if (
      (infos?.title?.en || infos?.title?.fr) &&
      (infos?.subtitle?.en || infos?.subtitle?.fr) &&
      infos?.beginDate && infos?.endDate &&
      (infos?.laps || infos?.calories)
    )
      return true;
    return false;
  };

  const handleChangeRuleType = (type, value) => {
    const newInfos = {...infos};

    if (type === 'calories') {
      newInfos.calories = value;
      newInfos.laps = undefined;
      newInfos.time = undefined;
    }
    else if (type === 'laps') {
      newInfos.calories = undefined;
      newInfos.laps = value;
      newInfos.time = undefined;
    }
    setInfos(newInfos);
  }

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

 const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  return (
    <EditContainer
      onSave={() => launchCreationChallenge(
        onClose, refetch, infos, setInfos,
        createChallenge, usersCompany
      )}
      title={t("Create Challenge")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
     <Row>
        <Col className="upload" span={13}>
          <div className="editPicture">{t("MINIATURE IMAGE")}</div>
          <Picture
            disableDelete={true}
            onChange={(e) => handleChange("miniatureImage", e)}
          />
        </Col>
        <Col className="upload" span={11}>
          <div className="editPicture">{t("FULL SCREEN IMAGE")}</div>
          <Picture
            disableDelete={true}
            onChange={(e) => handleChange("fullScreenImage", e)}
          />
        </Col>
      </Row>
      <Rules
        updateRuleValue={handleChange}
        updateRuleType={handleChangeRuleType}
        dropdownInialState="laps"
        list={challengeRules}
      />
      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            onChange={(e) => handleChange("beginDate", e)}
          />
        </Col>
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            onChange={(e) => handleChange("endDate", e)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>
        <Row className="horizontalRow">
          <Col span={11}>
            <Input
              title="en"
              onChange={(value) => beforeSetText("title", "en", value)}
            />
          </Col>
          <Col span={11}>
            <Input
              title="fr"
              onChange={(value) => beforeSetText("title", "fr", value)}
            />
          </Col>
        </Row>

        <Col span={21}>
          <div className="descriptionStyle">{t("DESCRIPTION")} </div>
          <Divider />
        </Col>

        <Row className="horizontalRow">
          <Col span={11}>
            <TextAreaInput
              title="en"
              rows={4}
              onChange={(value) => beforeSetText("subtitle", "en", value)}
            />
          </Col>
          <Col span={11}>
            <TextAreaInput
              title="fr"
              rows={4}
              onChange={(value) => beforeSetText("subtitle", "fr", value)}
            />
          </Col>
        </Row>
    </EditContainer>
  );
};

export default CreateAdminChallenge;
