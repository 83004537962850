export const launchUpdateSession = async (infos, updateSession, id, onClose) => {
    await updateSession({
      variables: {
        id: id,
        status: infos.status
      }
    }).catch((error) => {
      alert(error);
    });
    onClose();
  };