import React, { useState } from "react";
import PropTypes from "prop-types";
import { Image, Modal } from "antd";
import ImageUploader from "react-images-upload";
import ButtonCustom from "../ButtonCustom";
import "./ProfilePictureStyle.less";

const Picture = ({ picture, onChange, onDelete, disableDelete, dontShow }) => {
  const [opened, setOpened] = useState(false);
  const [image, setImage] = useState(picture);

  const changePicture = (picture, pictureUrl) => {
    if (pictureUrl.length) {
      const url = pictureUrl[pictureUrl.length - 1].replace(/name=.*;/, "");
      setImage(url);
      onChange(url);
    }
  };

  const deletPicture = () => {
    onDelete(image);
    setOpened(false);
    setImage(undefined);
  };

  const beforeOk = () => {
    setOpened(false);
    if (dontShow) setImage(undefined);
  };

  return (
    <div className="pictureContainer" id="ProfilePicture">
      {!dontShow && image ? (
        <div
          className="profilePictureContainer"
          onClick={() => setOpened(true)}
        >
          <Image
            src={image}
            className="profilePicture"
            alt="profilePicture"
            preview={false}
          />
        </div>
      ) : (
        <div onClick={() => setOpened(true)} className="noPicture">
          UPLOAD
        </div>
      )}
      <Modal
        className="style"
        visible={opened}
        maskClosable={false}
        onCancel={() => setOpened(false)}
        title={"Picture"}
        footer={[
          <ButtonCustom
            onClick={deletPicture}
            key="delete"
            title="Delete"
            type="danger"
            disabled={disableDelete || !image}
          />,
          <ButtonCustom
            onClick={beforeOk}
            key="ok" 
            title="OK"
            type="primary" 
          />
        ]}
        bodyStyle={{
          minHeight: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly"
        }}
      >
        <div className="uploadModal">
          {image ? (
            <Image
              src={image}
              className="picturePreview"
              alt="profilePicturePreview"
              preview={false}
            />
          ) : null}
          {!!onChange && (
            <ImageUploader
              singleImage={true}
              buttonText="Choose images"
              onChange={changePicture}
              imgExtension={[".jpg", ".png", ".jpeg"]}
              maxFileSize={5242880}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

Picture.propTypes = {
  picture: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  dontShow: PropTypes.bool,
  disableDelete: PropTypes.bool
};

Picture.defaultProps = {
  onDelete: () => {},
  disableDelete: false,
  dontShow: false
};

export default Picture;
