import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("NAME")}</div>,
      dataIndex: "name",
      render: (name) => <div className="tableValue">{name}</div>
    },
    {
      title: <div className="tableHeading">{t("ACTIVE")}</div>,
      dataIndex: "active",
      render: (active) =>
        !active ? (
          <CloseCircleOutlined className="closeOutlined" />
        ) : (
          <CheckCircleOutlined className="checkOutlined" />
        )
    },
    {
      title: <div className="tableHeading">{t("DURATION")}</div>,
      dataIndex: "duration",
      render:(duration) => duration ? (
            <div className="tableValue">{duration / 60000 / 60 / 24}</div>
          ) : (
            <div className="tableValue">{t("UNLIMITED")}</div>
          )
    }
  ];

  return columns;
}


export const getContentRows = (data,t) => {
  return ( 
    !data || !data.getAllAccountPlans
      ? []
      : data.getAllAccountPlans.map(({ 
        id, name, active, duration 
      }) => ({
          key: id,
          id,
          name,
          active,
          duration
        })
    )
  )
}

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllAccountPlans || 
      data.getAllAccountPlans.length === 0 ||
      data.getAllAccountPlans.length < number
    ) return true;
  return false;
}
