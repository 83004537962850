import React, { useContext } from "react";
import { useMutation, gql } from "@apollo/client";
import "./LoginStyle.less";
import LogoEveia from "../../assets/Images/LogoEveia.png";
import { Form, Input } from "antd";
import ButtonCustom from "../../Components/ButtonCustom";
import { useTranslation } from "react-i18next";

const LOGIN = gql`
  mutation login($email: String!, $password: String!, $admin: Boolean!) {
    login(email: $email, password: $password, admin: $admin)
  }
`;

const Login = ({ AuthContext }) => {
  const { t } = useTranslation(['input', 'button']);
  const [login] = useMutation(LOGIN);
  const { signIn } = useContext(AuthContext);

  const launchMutation = (email, password) => {
    try {
      login({
        variables: {
          email,
          password,
          admin: true
        },
        update: (cache, res) => {
          if (res && res.data && res.data.login) {
            signIn(res.data.login);
            window.location = "/";
          }
        }
      }).catch((error) => {});
    } catch (error) {
      console.log("error");
    }
  };

  const onFinish = (values) => {
    launchMutation(values.email, values.password);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="loginForm"
    >
      <section className="loginSection">
        <img src={LogoEveia} alt="img" width="220" height="57" />
      </section>
      <Form.Item
        label={t("input:email.label")}
        name="email"
        className="loginItem"
        rules={[
          {
            required: true,
            message: t('error:email')
          }
        ]}
      >
        <Input
          options={{ autoComplete: 'off'}}
          className="loginItemInput"
          placeholder={t("input:email.placeholder")}
        />
      </Form.Item>

      <Form.Item
        label={t("input:password.label")}
        name="password"
        className="loginItem"
        rules={[
          {
            required: true,
            message: t('error:password')
          }
        ]}
      >
        <Input.Password
          autoComplete="off"
          className="loginItemInput"
          placeholder={t("input:password.placeholder")}
        />
      </Form.Item>

      <Form.Item>
        <ButtonCustom
          title={t("button:Submit")}
          type="primary"
          htmlType="submit"
          className="buttonSubmit"
        />
      </Form.Item>
    </Form>
  );
};

export default Login;
