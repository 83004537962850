import React, { useState } from "react";
import { Row, Col, Divider } from "antd";
import CheckboxCustom from "../../Components/CheckboxCustom";
import Input from "../../Components/Input";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { CREATE_BADGE } from "./Requests";
import { useMutation } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import DropDown from "../../Components/DropDown";
import Picture from "../../Components/Picture";
import { useTranslation } from "react-i18next";
import Rules from "../../Components/Rules";
import { launchCreationBadge } from "./Utility/CreateBadgeUtility";

const CreateBadge = ({ onClose, history, refetch }) => {

  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [createBadge] = useMutation(CREATE_BADGE);
  const badgeRules = [
    "calories",
    "laps"
  ];
  
  const checkIfHasChanged = () => {
    if (
      (infos?.title?.en || infos?.title?.fr) &&
      (infos?.subtitle?.en || infos?.subtitle?.fr) &&
      (infos?.laps || infos?.calories)
    )
      return true;
    return false;
  };

  const handleChangeRuleType = (type, value) => {
    const newInfos = {...infos};

    if (type === 'calories') {
      newInfos.calories = value;
      newInfos.laps = undefined;
      newInfos.time = undefined;
    }
    else if (type === 'laps') {
      newInfos.calories = undefined;
      newInfos.laps = value;
      newInfos.time = undefined;
    }
    setInfos(newInfos);
  };


  const hangleChangeRuleValue = (type, value) => {
    const newInfos = {...infos};
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  return (
    <EditContainer
      onSave={() => launchCreationBadge(
        onClose, refetch, infos, setInfos, createBadge
      )}
      title={t("Create Badge")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
        <Col className="upload">
          <div className="editPicture">{t("PICTURE")}</div>
          <Picture
            disableDelete={true}
            onChange={(e) => handleChange("image", e)}
          />
        </Col>
        <Row className="horizontalRowStyle">
          <Col span={24} className="fullRight">
            <CheckboxCustom
              title={t("dropDown:Daily")}
              onChange={(e) => handleChange("daily", e)}
            />
          </Col>
        </Row>
        <Rules
          updateRuleValue={hangleChangeRuleValue}
          updateRuleType={handleChangeRuleType}
          dropdownInialState="laps"
          list={badgeRules}
        />
        <Col span={21}>
          <div className="titleStyle">{t("TITLE")}</div>
          <Divider />
        </Col>

        <Row className="horizontalRow">
          <Col span={11}>
            <Input
              title="en"
              onChange={(value) =>beforeSetText("title", "en", value)}
            />
          </Col>
          <Col span={11}>
            <Input
              title="fr"
              onChange={(value) =>beforeSetText("title", "fr", value)}
            />
          </Col>
        </Row>

        <Col span={21}>
          <div className="descriptionStyle">{t("DESCRIPTION")} </div>
          <Divider />
        </Col>

        <Row className="horizontalRow">
          <Col span={11}>
            <TextAreaInput
              title="en"
              rows={4}
              onChange={(value) =>beforeSetText("subtitle", "en", value)}
            />
          </Col>
          <Col span={11}>
            <TextAreaInput
              title="fr"
              rows={4}
              onChange={(value) =>beforeSetText("subtitle", "fr", value)}
            />
          </Col>
        </Row>
    </EditContainer>
  );
};

export default CreateBadge;
