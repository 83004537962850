import { gql } from "@apollo/client";

export const GET_ACCOUNTPLANS = gql`
  query getAllAccountPlans {
    getAllAccountPlans {
      id
      name
      duration
      active
      promotionalCode
      friendship
      activityHistory
      basicActivities
      multiActivities
      videogame
    }
  }
`;

export const GET_ACCOUNTPLAN = gql`
  query getAccountPlan($id: ID!) {
    getAccountPlan(id: $id) {
      id
      name
      duration
      active
      promotionalCode
      friendship
      activityHistory
      basicActivities
      multiActivities
      videogame
    }
  }
`;

export const CREATE_ACCOUNTPLAN = gql`
  mutation createAccountPlan(
    $name: String
    $duration: Float
    $active: Boolean
    $promotionalCode: String
    $friendship: Boolean
    $activityHistory: Boolean
    $basicActivities: Boolean
    $multiActivities: Boolean
    $videogame: Boolean
  ) {
    createAccountPlan(
      infos: {
        name: $name
        duration: $duration
        active: $active
        promotionalCode: $promotionalCode
        friendship: $friendship
        activityHistory: $activityHistory
        basicActivities: $basicActivities
        multiActivities: $multiActivities
        videogame: $videogame
      }
    ) {
      id
      name
      duration
      active
      promotionalCode
      friendship
      basicActivities
      activityHistory
      multiActivities
      videogame
    }
  }
`;

export const UPDATE_ACCOUNTPLAN = gql`
  mutation updateAccountPlan(
    $id: ID!
    $name: String
    $duration: Float
    $active: Boolean
    $promotionalCode: String
    $friendship: Boolean
    $activityHistory: Boolean
    $basicActivities: Boolean
    $multiActivities: Boolean
    $videogame: Boolean
  ) {
    updateAccountPlan(
      infos: {
        id: $id
        name: $name
        duration: $duration
        active: $active
        promotionalCode: $promotionalCode
        friendship: $friendship
        activityHistory: $activityHistory
        basicActivities: $basicActivities
        multiActivities: $multiActivities
        videogame: $videogame
      }
    ) {
      id
      name
      duration
      active
      promotionalCode
      friendship
      activityHistory
      basicActivities
      multiActivities
      videogame
    }
  }
`;

export const DELETE_ACCOUNTPLANS = gql`
  mutation deleteAccountPlansByAdmin($ids: [ID]!) {
    deleteAccountPlansByAdmin(ids: $ids)
  }
`;
