import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import buttonEn from "../assets/locales/en/button.json";
import buttonFr from "../assets/locales/fr/button.json";
import checkboxEn from "../assets/locales/en/checkbox.json";
import checkboxFr from "../assets/locales/fr/checkbox.json";
import dropDownEn from "../assets/locales/en/dropDown.json";
import dropDownFr from "../assets/locales/fr/dropDown.json";
import inputEn from "../assets/locales/en/input.json";
import inputFr from "../assets/locales/fr/input.json";
import commonEn from "../assets/locales/en/common.json";
import commonFr from "../assets/locales/fr/common.json";
import errorEn from  "../assets/locales/en/error.json";
import errorFr from  "../assets/locales/fr/error.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr',
    fallbackLng: "en",
    whitelist: ['en', 'fr'],
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true 
    },
    resources: {
      en: {
        common: commonEn,
        button: buttonEn,
        checkbox: checkboxEn,
        dropDown: dropDownEn,
        input: inputEn,
        error: errorEn
      },
      fr: {
        common: commonFr,
        button: buttonFr,
        checkbox: checkboxFr,
        dropDown: dropDownFr,
        input: inputFr,
        error: errorFr
      }
    },
    ns: ["common", "button", "checkbox", "dropDown", "input", "error"],
    defaultNS: "common"
  });

export default i18n;
