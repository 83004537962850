import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
   const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("TITLE")}</div>,
      dataIndex: "title",
      render: (title) => <div className="tableValue">{title}</div>
    },
    {
      title: <div className="tableHeading">{t("DESCRIPTION")}</div>,
      dataIndex: "subtitle",
      render: (subtitle) => <div className="tableValue">{subtitle}</div>
    }
  ];

  return columns;
}

export const getContentRows = (data) => {
  return ( 
    !data || !data.getAllWorlds
      ? []
      : data.getAllWorlds.map(
          ({ id, title, subtitle, titles, subtitles, images }) => ({
            key: id,
            id,
            title,
            subtitle,
            titles,
            subtitles,
            images
          })
        )
    )
}

export const handleDeleteWorlds = async (
    deleteWorlds, selectedRowKeys, 
    setSelectedRowKeys, refetch
  ) => {
    try {
      await deleteWorlds({
        variables: { ids: selectedRowKeys },
        update: (cache, res) => {}
      });
      setSelectedRowKeys([]);
      refetch();
    } catch (error) {
      console.log("error", error);
    }
  };

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllWorlds || 
      data.getAllWorlds.length === 0 ||
      data.getAllWorlds.length < number
    ) return true;
  return false;
}