import { gql } from "@apollo/client";

export const GET_USER = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      profile {
        lastName
        firstName
      }
      email
      verified
      lastConnection
      phone {
        countryCode
        number
      }
      admin
      image
      plan
      company
      adminCompany
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserByAdmin(
    $id: ID!
    $firstName: String
    $lastName: String
    $image: Upload
    $email: String
    $countryCode: String
    $number: String
    $admin: Boolean
    $plan: ID
    $company: ID
    $adminCompany: Boolean
  ) {
    updateUserByAdmin(
      infos: {
        company: $company
        adminCompany: $adminCompany
        id: $id
        profile: { firstName: $firstName, lastName: $lastName }
        image: $image
        email: $email
        phone: { countryCode: $countryCode, number: $number }
        admin: $admin
        plan: $plan
      }
    ) {
      id
      profile {
        lastName
        firstName
      }
      email
      verified
      lastConnection
      phone {
        countryCode
        number
      }
      admin
      image
      plan
      company
      adminCompany
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePasswordByAdmin($userId: ID!, $newPassword: String!) {
    updatePasswordByAdmin(userId: $userId, newPassword: $newPassword)
  }
`;

export const GET_USERS = gql`
  query getAllUser(
    $number: Int
    $page: Int
    $company: ID
    $email: String
    $name: String
  ) {
    getAllUser(
      infos: {
        number: $number
        page: $page
        company: $company
        email: $email
        name: $name
      }
    ) {
      id
      profile {
        lastName
        firstName
      }
      lastConnection
      company
      email
      verified
      plan
    }
  }
`;

export const DELETE_USERS = gql`
  mutation deleteUsersByAdmin($ids: [ID!]) {
    deleteUsersByAdmin(users: $ids)
  }
`;

export const CREATE_USER = gql`
  mutation createUserByAdmin(
    $admin: Boolean
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $countryCode: String
    $number: String
    $plan: ID
    $company: ID
    $adminCompany: Boolean
  ) {
    createUserByAdmin(
      infos: {
        admin: $admin
        company: $company
        adminCompany: $adminCompany
        profile: { firstName: $firstName, lastName: $lastName }
        email: $email
        password: $password
        plan: $plan
        phone: { countryCode: $countryCode, number: $number }
      }
    ) {
      id
      profile {
        lastName
        firstName
      }
      email
      verified
      lastConnection
      phone {
        countryCode
        number
      }
      admin
      image
      plan
      company
      adminCompany
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      id
      email
      language
      company
      image
      adminCompany
      admin
    }
  }
`;


export const GET_FRIENDS = gql`
  query getFriends ($userId: ID!) {
    getFriends (userId: $userId) {
      id
      userId
      list {
        friend {
          id
          email
        }
        status
      }
    }
  }
`;

export const ADD_FRIEND_FROM_ADMIN = gql`
  mutation addFriendFromAdmin ($userId: ID! $friendId: ID!) {
    addFriendFromAdmin (userId: $userId, friendId: $friendId) {
      id
      userId
      list {
        friend {
          id
        }
        status
      }
    }
  }
`;