import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import Input from "../../Components/Input";
import { useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import "../../UI/CommonStyles.less";
import { GET_FRIENDS, ADD_FRIEND_FROM_ADMIN } from "./Requests";
import DropDown from "../../Components/DropDown";
import ButtonCustom from "../../Components/ButtonCustom";
import { useTranslation } from "react-i18next";

const Friends = ({ userId }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [friend, setFriend] = useState("");
  const [addFriendFromAdmin] = useMutation(ADD_FRIEND_FROM_ADMIN);
  const { data, refetch } = useQuery(GET_FRIENDS, {
    variables: {
      userId
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (data && data.getFriends) setInfos({ ...data.getFriends });
  }, [data]);

  const addFriend = async () => {
    if (!friend || friend === "") return;
    try {
      await addFriendFromAdmin({
        variables: {
          userId,
          friendId: friend
        }
      });
      setFriend("");
      refetch();
    } catch (error) {
      console.log("error", error);
    }
    
  };

  const userStatusRule = ["pending", "asked", "accepted"];

  return infos ? (
    <React.Fragment>
      <Col span={21}>
        <div className="descriptionStyle">{t("Add friends")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <ButtonCustom
            title={t("button:add friend")}
            type="primary"
            className="buttonAddFriend"
            onClick={addFriend}
          />
        </Col>

        <Col span={11}>
          <Input
            placeholder={t("input:userId.label")}
            value={friend}
            onChange={(e) => setFriend(e)}
          />
        </Col>
      </Row>

      {infos && infos.list && infos.list.length ? (
        <Col span={21}>
          <div className="descriptionStyle">{t("Friends")} </div>
          <Divider />
        </Col>
      ) : null}
      <Row className="horizontalRow">
        {infos.list.map((e) => (
           <Row className="horizontalRowStyle"key={e.friend.id}>
            <Col span={11}>
              <Input title={t("input:userId.label")} value={e.friend.email} disabled={true} />
            </Col>
            <Col span={11}>
              <DropDown
                title={t("input:userStatus.label")}
                items={userStatusRule}
                initialState={e.status}
                disabled={true}
              />
            </Col>
          </Row>
        ))}
      </Row>
    </React.Fragment>
  ) : null;
};

Friends.propTypes = {
  userId: PropTypes.string.isRequired
};

Friends.defaultProps = {};

export default Friends;
