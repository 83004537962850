import { gql } from '@apollo/client';

export const GET_WORLDS = gql`
  query getAllWorlds (
    $title: String
    $number: Int
    $page: Int
    $id: ID
    $activity: ID
  ) {
    getAllWorlds (
      infos: {
        title: $title
        number: $number
        page: $page
        id: $id
        activity: $activity
      }
    ) {
      id
      title
      titles {
        en
        fr
      }
      subtitle
      subtitles {
        en
        fr
      }
      images
    }
  }
`;

export const UPDATE_WORLD = gql`
  mutation updateWorld (
    $id: ID!
    $titleEn: String
    $titleFr: String
    $subEn: String
    $subFr: String
  ) {
    updateWorld (
      id: $id
      infos: {
        title: {
          en: $titleEn
          fr: $titleFr
        }
        subtitle: {
          en: $subEn
          fr: $subFr
        }
      }
    ) {
      id
      title
      titles {
        en
        fr
      }
      subtitle
      subtitles {
        en
        fr
      }
      images
    }
  }
`;

export const UPDATE_IMAGES = gql`
  mutation updateWorldImage ($id: ID!, $newImage: Upload, $deletedImage: String) {
    updateWorldImage (id: $id, newImage: $newImage, deletedImage: $deletedImage) {
      id
      title
      titles {
        en
        fr
      }
      subtitle
      subtitles {
        en
        fr
      }
      images
    }
  }
`;

export const DELETE_WORLDS = gql`
  mutation deleteWorlds($ids: [ID!]) {
    deleteWorlds (ids: $ids)
  }
`;

export const CREATE_WORLD = gql`
  mutation createWorld (
    $titleEn: String
    $titleFr: String
    $subEn: String
    $subFr: String
  ) {
    createWorld (
      infos: {
        subtitle: { en: $subEn, fr: $subFr }
        title: { en: $titleEn, fr: $titleFr }
      }
    ) {
      id
      titles {
        en
        fr
      }
      subtitles {
        en
        fr
      }
      images
    }
  }
`;
