import PropTypes from "prop-types";
import { Layout } from "antd";
import ButtonCustom from "../ButtonCustom";
import "./ActionsStyle.less";
import { useTranslation } from "react-i18next";

const Actions = ({ disableDelete, deletePicked, add, disbaleAdd }) => {
  const { t } = useTranslation();
  return (
    <Layout className="actionsContainer">
      <div>
        <ButtonCustom
          className="buttonDelete"
          title={t("button:Delete")}
          disabled={disableDelete}
          onClick={deletePicked}
        />

        <ButtonCustom
          className="buttonCreate"
          title={t("button:Create")}
          disabled={disbaleAdd}
          onClick={add}
        />
      </div>
    </Layout>
  );
};

Actions.propTypes = {
  deletePicked: PropTypes.func,
  add: PropTypes.func,
  disableDelete: PropTypes.bool,
  disbaleAdd: PropTypes.bool
};

Actions.defaultProps = {
  deletePicked: () => {},
  add: () => {},
  disableDelete: false,
  disbaleAdd: false
};

export default Actions;
