import React from "react";
import CompanyUsers from "../../Pages/CompanyUsers";
import CompanyChallenges from "../../Pages/CompanyChallenges";
import CompanyDevices from "../../Pages/CompanyDevices";
import AuthenticatedRoute from "../../Pages/App/AuthenticatedRoute";
import { Route, Switch, Redirect } from "react-router-dom";
import Logout from "../../Pages/Logout";
import Login from "../../Pages/Login";

const CompanyNavigator = ({ AuthContext, state }) => {
  return (
    <Switch>
      <Route exact path="/signin" AuthContext={AuthContext}>
        {(props) => <Login {...props} AuthContext={AuthContext} />}
      </Route>
      <Route exact path="/logout" AuthContext={AuthContext}>
        {(props) => <Logout {...props} AuthContext={AuthContext} />}
      </Route>

      <AuthenticatedRoute
        path="/companyUsers"
        auth={state.userToken !== null}
        exact
        component={(props) => <CompanyUsers AuthContext={AuthContext} {...props} />}
      />

      <AuthenticatedRoute
        path="/companyChallenges"
        auth={state.userToken !== null}
        exact
        component={(props) => <CompanyChallenges AuthContext={AuthContext} {...props} />}
      />

      <AuthenticatedRoute
        path="/companyDevices"
        auth={state.userToken !== null}
        exact
        component={(props) => <CompanyDevices AuthContext={AuthContext} {...props} />}
      />

     <AuthenticatedRoute
        path="*"
        auth={state.userToken !== null}
        exact
        component={() => <Redirect to="/companyUsers" />}
      />
    </Switch>
  );
};

export default CompanyNavigator;
