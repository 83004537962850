export const launchCreationAccountPlan = async (
   onClose, refetch, infos, setInfos, createAccountPlan
  ) => {
  try {
      if (!infos.name) return;
      const { duration, ...fields } = infos;
      createAccountPlan({
        variables: {
          duration: duration * 60000 * 60 * 24,
          ...fields
        },
        update: (cache, res) => {
          setInfos(undefined);
          onClose();
          refetch();
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};
