import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { readableDate } from "../../../Tools/Date";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
    const columns = [
    {
      title: <div className="tableHeading">{t("ID")}</div>,
      dataIndex: "id",
      render: (id) => <CopyId id={id} />
    },
    {
      title: <div className="tableHeading">{t("BEGINDATE")}</div>,
      dataIndex: "beginDate",
      render: (date) => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("ENDDATE")}</div>,
      dataIndex: "endDate",
      render: (date) => <div className="tableValue">{readableDate(date)}</div>
    },
    {
      title: <div className="tableHeading">{t("TIME")}</div>,
      dataIndex: "time",
      render: (time) => <div className="tableValue">{time}</div>
    },
    {
      title: <div className="tableHeading">{t("DISTANCE")}</div>,
      dataIndex: "distance",
      render: (distance) => <div className="tableValue">{distance}</div>
    },
    {
      title: <div className="tableHeading">{t("DAILY")}</div>,
      dataIndex: "daily",
      render: (daily) => (
        !daily ? 
        <CloseCircleOutlined className="closeOutlined"/> 
        : <CheckCircleOutlined className="checkOutlined"/>
      )
    },
    {
      title: <div className="tableHeading">{t("STATUS")}</div>,
      dataIndex: "status"
    }
  ];

  return columns;
}


export const getContentRows = (data, t) => {
  
  const getStatusValue = (value) => {
    if (value === "off")
      return <div className="buttonActive">{t("button:Off")}</div>;
    else if (value === "on")
      return <div className="buttonOnGoing">{t("button:On")}</div>;
    else if (value === "pause")
      return <div className="buttonPending">{t("button:Pause")}</div>;
    else 
      return <div className="buttonDone">{t("button:Done")}</div>;
  };

  return ( 
    !data || !data.getAllSession ? []
      : data.getAllSession.map((session) => ({
          id: session.id,
          logs: session.id,
          key: session.id,
          status: getStatusValue(session.status),
          beginDate: session.beginDate,
          endDate: session.endDate,
          time: session.time,
          daily: session.daily,
          distance: session.laps,
          edit: session
        })
      )
    )
}

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllSession || 
      data.getAllSession.length === 0 ||
      data.getAllSession.length < number
    ) return true;
  return false;
}

