import moment from "moment";

export const readableDate = (dateString, hour) => {
  if (!dateString)
    return 'undefined'
  const date = new moment(dateString).toDate();
  
  const formatedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  if (hour) {
    return formatedDate + ` - ${date.getHours()}:${date.getMinutes()}`;
  }
  return formatedDate;
};