import React from "react";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";
import "./InputStyle.less";

const Input = ({
  placeholder,
  onChange,
  password,
  value,
  disabled,
  title
}) => {
  return (
    <div className="verticalContainer">
      <div className="title">{title}</div>
      <DebounceInput
        placeholder={placeholder}
        className="boxContainer"
        debounceTimeout={500}
        onChange={(event) => onChange(event.target.value)}
        type={password ? "password" : "text"}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

Input.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  password: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  title: "",
  label: "Input label...",
  placeholder: "Placeholder...",
  onChange: () => {},
  password: false,
  value: "",
  disabled: false
};

export default Input;
