import React, { useState } from "react";
import { Layout, Table } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_COMPANIES, GET_COMPANIES } from "./Requests";
import Header from "../../Components/Header";
import "../../UI/CommonStyles.less";
import Actions from "../../Components/Actions";
import Footer from "../../Components/Footer";
import CreateCompany from "./CreateCompany";
import EditCompany from "./EditCompany";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  handleDeleteComapnies,
  checkIfNextDisabled
} from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Companies = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [name, setName] = useState("");
  const [number, setNumber] = useState(20);
  const [page, setPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteCompanies] = useMutation(DELETE_COMPANIES);
  const { data, refetch } = useQuery(GET_COMPANIES, {
    variables: {
      number,
      page,
      name
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDeleteComapnies(
      selectedRowKeys,
      setSelectedRowKeys,
      refetch,
      deleteCompanies
    );
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditCompany
          history={history}
          onClose={() => setIdClicked(undefined)}
          refetch={refetch}
          id={idClicked}
        />
      )}
      {showModal && (
        <CreateCompany
          onClose={() => {
            setShowModal(false);
            refetch();
          }}
        />
      )}

      <Header name={t("COMPANIES")}>
        <Filters setName={setName} />
      </Header>

      <Content className="card">
        <Actions
          deletePicked={() => setDeleteModal(true)}
          add={() => setShowModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              page={page}
              setPage={setPage}
              setNumber={setNumber}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Companies;
