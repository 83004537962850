import React, { useState, useEffect } from "react";
import EditContainer from "../../Components/EditContainer";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import DropDown from "../../Components/DropDown";
import DatePickerCustom from "../../Components/DatePickerCustom";
import CheckboxCustom from "../../Components/CheckboxCustom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SESSION, UPDATE_SESSION } from "./Requests";
import "../../UI/CommonStyles.less";
import Input from "../../Components/Input";
import InputNumber from "../../Components/InputNumber";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { launchUpdateSession } from "./Utility/EditSessionUtility";
import { getModifiedFields, checkIfHasChanged } from "../../Tools/fields";

const EditSession = ({ id, onClose }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [updateSession] = useMutation(UPDATE_SESSION);
  const { data, loading, error, refetch } = useQuery(
      GET_SESSION, 
      { variables: { id }, 
      fetchPolicy: 'network-only' 
    });
  
  const beforeUpdate = async () => {
    const modifiedInfos = getModifiedFields(infos, {...data.getSession });
    let result = false;
    if (Object.keys(modifiedInfos).length > 0)
      result = await launchUpdateSession(modifiedInfos, updateSession, id, onClose);
    if (result) {
      setInfos(undefined);
      refetch();
    }
  }

  useEffect(() => {
    if (data && data.getSession) setInfos(data.getSession);
  }, [data, data?.getSession]);

  if (loading || !data || !data.getSession) return "Loading...";
  if (error) return "Can't get this session informations";

  
  return infos ? (
    <EditContainer
      title={t("Edit Session")}
      onSave={beforeUpdate}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged(infos, {...data.getSession})}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:email.label")}
            placeholder={t("input:email.placeholder")}
            value={infos.user.email}
            debounceTimeout={500}
            disabled={true}
          />
        </Col>

        <Col span={11} className="fullRight">
          <CheckboxCustom
            title={t("dropDown:Daily")}
            disabled={true}
            defaultChecked={infos.daily}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            disabled={true}
            value={infos.beginDate ? moment(infos.beginDate) : undefined}
          />
        </Col>
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            disabled={true}
            value={infos.endDate ? moment(infos.endDate) : undefined}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <InputNumber
            title={t("input:time.label")}
            placeholder={t("input:time.placeholder")}
            value={infos.time ? infos.time : undefined}
            disabled={true}
          />
        </Col>
        <Col span={11}>
          <InputNumber
            title={t("input:calories.label")}
            placeholder={t("input:calories.placeholder")}
            value={infos.calories ? Math.round(infos.calories) : undefined}
            disabled={true}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <InputNumber
            title={t("input:laps.label")}
            placeholder={t("input:laps.placeholder")}
            value={infos.laps ? infos.laps : undefined}
            disabled={true}
          />
        </Col>

        <Col span={11}>
          <DropDown
            title={t("dropDown:Status")}
            onItemClicked={(e) => setInfos({ ...infos, status: e })}
            initialState={infos.status}
            items={["on", "off", "pause", "done"]}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <InputNumber
            title={t("input:lapsOffline.label")}
            placeholder={t("input:lapsOffline.placeholder")}
            value={infos.lapsOffline ? infos.lapsOffline : undefined}
            disabled={true}
          />
        </Col>

        <Col span={11}>
          <InputNumber
            title={t("input:caloriesOffline.label")}
            placeholder={t("input:caloriesOffline.placeholder")}
            value={infos.caloriesOffline ? infos.caloriesOffline : undefined}
            disabled={true}
          />
        </Col>
      </Row>
    </EditContainer>
  ) : null;
};

EditSession.propTypes = {
  onClose: PropTypes.func
};

EditSession.defaultProps = {
  onClose: () => {}
};

export default EditSession;
