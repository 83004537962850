import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import "../../Pages/App/App.less";
import { useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { GET_COMPANY } from "../../Pages/Companies/Requests";
import ContactEveia from "../../Pages/ContactEveia";
import LogoEveia from "../../assets/Images/LogoEveia.png";
import { useTranslation } from "react-i18next";
import { HeaderMenu, FooterMenu } from "./Utility"
import {
  UserOutlined,
  LaptopOutlined,
  RiseOutlined
} from "@ant-design/icons";

const adminSection = {
  1: "/companyUsers",
  2: "/companyChallenges",
  3: "/companyDevices"
};

const CompanyMenu = ({ companyId }) => {
  const { t } = useTranslation( "common");

  const [createModal, setCreateModal] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [selectedAdminKey, setSelectedAdminKey] = useState(
    Object.keys(adminSection)
      .filter((k) => adminSection[k] === history.location.pathname)
      .toString()
  );

  const goToAdminSection = (key) => {
    history.push(adminSection[key]);
  };

  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: companyId
    }
  });


  useEffect(() => {
    setSelectedAdminKey(
      Object.keys(adminSection)
        .filter((k) => adminSection[k] === location.pathname)
        .toString()
    );
  }, [location.pathname]);

  return (
    <Layout.Sider width={300} className="sider">
      <Menu
        mode="inline"
        className="mainMenu"
        selectedKeys={[selectedAdminKey]}
        defaultSelectedKeys={["1"]}
      >
        <HeaderMenu logo={companyId && data?.getCompany?.logo ? data.getCompany.logo : LogoEveia}/>
        
        <Menu.Item
          icon={<UserOutlined />}
          onClick={(e) => goToAdminSection(e.key)}
          key="1"
          className="mainMenuContent"
        >
          {t("Users")}
        </Menu.Item>

        <Menu.Item
          icon={<RiseOutlined />}
          onClick={(e) => goToAdminSection(e.key)}
          key="2"
          className="mainMenuContent"
        >
          {t("Challenges")}
        </Menu.Item>

        <Menu.Item
          icon={<LaptopOutlined />}
          onClick={(e) => goToAdminSection(e.key)}
          key="3"
          className="mainMenuContent"
        >
          {t("Devices")}
        </Menu.Item>

        <FooterMenu setCreateModal={setCreateModal} t={t} history={history}/>
        {createModal && <ContactEveia onClose={() => setCreateModal(false)} />}
      </Menu>
    </Layout.Sider>
  );
};

export default CompanyMenu;
