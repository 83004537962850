import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import ButtonCustom from "../ButtonCustom";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import "./DeleteModalStyle.less";

const DeleteModal = ({ onClose, onDelete, show}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={show}
      maskClosable={false}
      onCancel={onClose}
      onOk={onDelete}
      className="deleteModal"
      title={t('common:Confirm Delete')}
      footer={[
        <ButtonCustom
          key="back"
          title={t("button:CANCEL")}
          className="modalButton"
          onClick={onClose}
        />,
        <ButtonCustom
          key="submit"
          title={t("button:Confirm")}
          className="modalButton"
          type="primary"
          onClick={onDelete}
        />
      ]}
      >

    </Modal>
  );
}

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  show: PropTypes.bool
};

DeleteModal.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
  show: false
};

export default DeleteModal;