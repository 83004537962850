import React, { useState } from "react";
import { Layout, Table } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SESSIONS, DELETE_SESSIONS } from "./Requests";
import EditSession from "./EditSession";
import ShowLogs from "./ShowLogs";
import "../../UI/CommonStyles.less";
import Actions from "../../Components/Actions";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled
} from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Sessions = ({ location }) => {
  const { t } = useTranslation();
  const [idFilter, setIdFilter] = useState(
    location?.state?.userId ? location.state.userId : undefined
  );
  const [activityId, setActivityId] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [roomId, setRoomId] = useState(undefined);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [daily] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteSessions] = useMutation(DELETE_SESSIONS);
  const [idClicked, setIdClicked] = useState(undefined);
  const [logClicked, setLogClicked] = useState(undefined);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const { data, refetch } = useQuery(GET_SESSIONS, {
    variables: {
      user: idFilter,
      page,
      number,
      daily,
      activity: activityId,
      room: roomId,
      status
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const handleDeleteSessions = async () => {
    await deleteSessions({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDeleteSessions();
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditSession
          refetch={refetch}
          onClose={() => {
            setIdClicked(undefined);
            refetch();
          }}
          id={idClicked}
        />
      )}
      {logClicked !== undefined && (
        <ShowLogs
          onClose={() => {
            setLogClicked(undefined);
            refetch();
          }}
          sessionId={logClicked}
        />
      )}

      <Header name={t("SESSIONS")}>
        <Filters
          setIdFilter={setIdFilter}
          idFilter={idFilter}
          setActivityId={setActivityId}
          setRoomId={setRoomId}
          setStatus={setStatus}
        />
      </Header>

      <Content className="card">
        <Actions
          disbaleAdd={true}
          disableDelete={!selectedRowKeys.length}
          deletePicked={() => setDeleteModal(true)}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data, t)}
          columns={getContentColumns(t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              page={page}
              setNumber={setNumber}
              setPage={setPage}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Sessions;
