export const launchUpdateWorld = async (infos, updateWorld, id, onClose) => {
    const { titles, subtitles } = infos;
    try {
      updateWorld({
        variables: {
          id,
          titleFr: titles?.fr,
          titleEn: titles?.en,
          subFr: subtitles?.fr,
          subEn: subtitles?.en
        },
        // update: (cache, res) => {}
      });
      onClose();
    } catch (error) {
      console.log("error", error);
    }
  };

export const handleAddNewImage = (newImage, updateWorldImage, infos, setInfos) => {
    const { id } = infos;
    try {
      updateWorldImage({
        variables: {
          id,
          newImage,
        },
        update: (cache, res) => {
          if (res && res.data && res.data.updateWorldImage)
            setInfos(res.data.updateWorldImage);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};

export const handleDeleteImage = (deletedImage, updateWorldImage, infos, setInfos) => {
    const { id } = infos;

    try {
      updateWorldImage({
        variables: {
          id,
          deletedImage
        },
        update: (cache, res) => {
          if (res && res.data && res.data.updateWorldImage)
            setInfos(res.data.updateWorldImage);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
};
