import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Layout, Table } from "antd";
import { GET_ACTIVITIES, DELETE_ACTIVITIES } from "./Requests";
import "../../UI/CommonStyles.less";
import Filters from "./Filters";
import Footer from "../../Components/Footer";
import Actions from "../../Components/Actions";
import Header from "../../Components/Header";
import EditActivity from "./EditActivity";
import CreateActivity from "./CreateActivity";
import { useTranslation } from "react-i18next";
import {
  getContentRows,
  getContentColumns,
  checkIfNextDisabled,
  handleDropdownChange
} from "./Utility/TableUtility";
import DeleteModal from "../../Components/DeleteModal";

const { Content } = Layout;

const Activities = ({ history }) => {
  const { t } = useTranslation();
  const [idClicked, setIdClicked] = useState(undefined);
  const [createModal, setCreateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(20);
  const [title, setTitle] = useState(null);
  const [world, setWorld] = useState(null);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [canChoosePlayers, setCanChoosePlayers] = useState(null);
  const [canBeMultiPlayer, setCanBeMultiPlayer] = useState(null);
  const [close, setClose] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteMultipleActivities] = useMutation(DELETE_ACTIVITIES);

  const { data, refetch } = useQuery(GET_ACTIVITIES, {
    variables: {
      number,
      page,
      title,
      world,
      beginDate,
      endDate,
      canChoosePlayers,
      canBeMultiPlayer,
      close
    }
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const rowIdClicked = (record) => {
    return {
      onClick: () => {
        setIdClicked(record.id);
      }
    };
  };

  const handleDelete = async () => {
    await deleteMultipleActivities({
      variables: {
        ids: selectedRowKeys
      },
      update: (cache, res) => {}
    }).catch((error) => {
      alert(error);
    });
    refetch();
    setSelectedRowKeys([]);
  };

  const beforeCloseDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
  }

  const beforeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setSelectedRowKeys([]);
    handleDelete();
  }

  return (
    <Layout className="pageContainer">
      {idClicked !== undefined && (
        <EditActivity
          refetch={refetch}
          history={history}
          onClose={() => setIdClicked(undefined)}
          id={idClicked}
        />
      )}
      {createModal && (
        <CreateActivity
          refetch={refetch}
          onClose={() => setCreateModal(false)}
        />
      )}
      <Header name={t("ACTIVITIES")}>
        <Filters
          setNumber={setNumber}
          setTitle={setTitle}
          setWorld={setWorld}
          setBeginDate={setBeginDate}
          setEndDate={setEndDate}
          handleDropdownChange={(e) => handleDropdownChange(
            e, setCanChoosePlayers, setCanBeMultiPlayer
          )}
          setClose={setClose}
        />
      </Header>

      <Content className="card">
        <Actions
          add={() => setCreateModal(true)}
          deletePicked={() => setDeleteModal(true)}
          disableDelete={!selectedRowKeys.length}
        />
        <Table
          pagination={false}
          rowSelection={rowSelection}
          dataSource={getContentRows(data)}
          columns={getContentColumns(close, t)}
          onRow={rowIdClicked}
          footer={() => (
            <Footer
              page={page}
              setNumber={setNumber}
              setPage={setPage}
              nextDisable={checkIfNextDisabled(data, number)}
            />
          )}
        />
      </Content>
      <DeleteModal
        show={showDeleteModal}
        onClose={beforeCloseDeleteModal}
        onDelete={beforeConfirmDeleteModal}
      />
    </Layout>
  );
};

export default Activities;
