import { gql } from "@apollo/client";

export const GET_DEVICES = gql`
  query getAllDevices(
    $number: Int
    $page: Int
    $company: ID
    $machine: String
    $user: ID
  ) {
    getAllDevices(
      infos: {
        page: $page
        number: $number
        company: $company
        machine: $machine
        user: $user
      }
    ) {
      id
      machine
      user
      laps
      company
      lastConnection
      gapLaps
    }
  }
`;

export const GET_DEVICE = gql`
  query getDeviceById($id: ID!) {
    getDeviceById(id: $id) {
      id
      machine
      user
      laps
      company
      lastConnection
      gapLaps
    }
  }
`;

export const GET_DEVICE_BY_MACHINE = gql`
  query getDeviceByMachine($machine: String!) {
    getDeviceByMachine(machine: $machine) {
      id
      machine
      user
      laps
      company
      lastConnection
      gapLaps
    }
  }
`;

export const DELETE_DEVICES = gql`
  mutation deleteMultipleDevices($ids: [ID!]!) {
    deleteMultipleDevices(ids: $ids)
  }
`;

export const UPDATE_DEVICE = gql`
  mutation updateDeviceLaps($laps: Int!) {
    updateDeviceLaps(laps: $laps) {
      id
      machine
      user
      laps
      company
      lastConnection
      gapLaps
    }
  }
`;

export const CHECK_DEVICE = gql`
  mutation checkDevice(
    $id: ID
    $machine: String
    $laps: Int
    $userId: ID
    $company: ID
  ) {
    checkDevice(
      infos: {
        id: $id
        machine: $machine
        laps: $laps
        userId: $userId
        company: $company
      }
    ) {
      id
      machine
      user
      laps
      company
      lastConnection
      gapLaps
    }
  }
`;
