import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Alert } from "antd";
import Input from "../../Components/Input";
import DatePickerCustom from "../../Components/DatePickerCustom";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import { CREATE_CHALLENGE } from "./Requests";
import { GET_COMPANIES } from "../Companies/Requests";
import { useMutation, useQuery } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import DropDown from "../../Components/DropDown";
import Picture from "../../Components/Picture";
import { useTranslation } from "react-i18next";
import {
  launchCreationChallenge,
  beforeSetUsersCompany
} from "./Utility/CreateChallengeUtility";
import Rules from "../../Components/Rules";

const CreateChallenge = ({ onClose, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState(undefined);
  const [createChallenge, { error }] = useMutation(CREATE_CHALLENGE);
  const [usersCompany, setUsersCompany] = useState(undefined);
  const [ showAlert, setShowAlert ] = useState(false);
  const { data } = useQuery(GET_COMPANIES, {
    variables: {
      number: 100,
      page: 1
    }
  });
  const challengeRules = [
    "calories",
    "laps"
  ];
  useEffect(() => {
    if (error)
      setShowAlert(true);
  }, [error]);

  const companiesData = data?.getAllCompanies
      ? data?.getAllCompanies?.map((item, index) => {
        return item.name;
      }) : [];
  companiesData.unshift("...");

  const checkIfHasChanged = () => {
    if (
      (infos?.title?.en || infos?.title?.fr) &&
      (infos?.subtitle?.en || infos?.subtitle?.fr) &&
      infos?.beginDate && infos?.endDate &&
      (infos?.laps || infos?.calories)
    )
      return true;
    return false;
  };

  const handleChangeRuleType = (type, value) => {
    const newInfos = {...infos};

    if (type === 'calories') {
      newInfos.calories = value;
      newInfos.laps = undefined;
      newInfos.time = undefined;
    }
    else if (type === 'laps') {
      newInfos.calories = undefined;
      newInfos.laps = value;
      newInfos.time = undefined;
    }
    setInfos(newInfos);
  };


  const hangleChangeRuleValue = (type, value) => {
    const newInfos = {...infos};
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  const beforeSetText = (type, lang, value) => {
    const newInfos = { ...infos };
    newInfos[type] = { ...newInfos[type], [lang]: value };
    setInfos(newInfos);
  };


  return (
    <EditContainer
      onSave={() =>
        launchCreationChallenge(
          onClose,
          refetch,
          infos,
          setInfos,
          createChallenge,
          usersCompany
        )
      }
      title={t("Create Challenge")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
      {
        showAlert ?  <Alert message="Error" type="error" showIcon closable={true} onClose={() => setShowAlert(false)}/> : null
      }
      <Row>
        <Col className="upload" span={13}>
          <div className="editPicture">{t("MINIATURE IMAGE")}</div>
          <Picture
            disableDelete={true}
            onChange={(e) => handleChange("miniatureImage", e)}
          />
        </Col>
        <Col className="upload" span={11}>
          <div className="editPicture">{t("FULL SCREEN IMAGE")}</div>
          <Picture
            disableDelete={true}
            onChange={(e) => handleChange("fullScreenImage", e)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={24}>
          <DropDown
            title={t("dropDown:Specific Company")}
            onItemClicked={(name) =>
              beforeSetUsersCompany(name, data, setUsersCompany)
            }
            translate={false}
            items={companiesData}
          />
        </Col>
      </Row>
      <Rules
        updateRuleValue={hangleChangeRuleValue}
        updateRuleType={handleChangeRuleType}
        dropdownInialState="laps"
        list={challengeRules}
      />

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:Begin date")}
            onChange={(e) => handleChange("beginDate", e)}
          />
        </Col>
        <Col span={11}>
          <DatePickerCustom
            title={t("dropDown:End date")}
            onChange={(e) => handleChange("endDate", e)}
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="titleStyle">{t("TITLE")}</div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title="en"
            onChange={(value) =>
              beforeSetText("title", "en", value)
            }
          />
        </Col>
        <Col span={11}>
          <Input
            title="fr"
            onChange={(value) =>
              beforeSetText("title", "fr", value)
            }
          />
        </Col>
      </Row>

      <Col span={21}>
        <div className="descriptionStyle">{t("DESCRIPTION")} </div>
        <Divider />
      </Col>

      <Row className="horizontalRow">
        <Col span={11}>
          <TextAreaInput
            title="en"
            rows={4}
            onChange={(value) =>
              beforeSetText("subtitle", "en", value)
            }
          />
        </Col>
        <Col span={11}>
          <TextAreaInput
            title="fr"
            rows={4}
            onChange={(value) =>
              beforeSetText("subtitle", "fr", value)
            }
          />
        </Col>
      </Row>
    </EditContainer>
  );
};

export default CreateChallenge;
