import React, { useState } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import TextAreaInput from "../../Components/TextAreaInput";
import "../../UI/CommonStyles.less";
import EditContainer from "../../Components/EditContainer";
import DropDown from "../../Components/DropDown";
import { useTranslation } from "react-i18next";

const CreateAdminChallenge = ({ onClose, history, refetch }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const checkIfHasChanged = () => {
    if (firstName && lastName && email) return true;
    return false;
  };

  const actionRule = ["add", "delete", "transfer", "other"];
  
  return (
    <EditContainer
      title={t("Manage company user")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
      contactModal={true}
    >
        <Row>
          <Col span={24}>
            <DropDown
              title={t("dropDown:Action")}
              items={actionRule}
            />
          </Col>
        </Row>

        <Row className="horizontalRowStyle">
          <Col span={11}>
            <Input
              title={t("input:firstName.label")}
              placeholder={t("input:firstName.placeholder")}
              onChange={setFirstName}
            />
          </Col>

          <Col span={11}>
            <Input
              title={t("input:name.label")}
              placeholder={t("input:name.placeholder")}
              onChange={setLastName}
            />
          </Col>
        </Row>

        <Row className="horizontalRowStyle">
          <Col span={24}>
            <Input
              title={t("input:email.label")}
              onChange={setEmail}
              placeholder={t("input:email.placeholder")}
            />
          </Col>
        </Row>

        <Row className="horizontalRowStyle">
          <Col span={24}>
            <TextAreaInput
              title={t("input:message.label")}
              rows={4}
            />
          </Col>
        </Row>
    </EditContainer>
  );
};

export default CreateAdminChallenge;
