import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Layout } from "antd";
import "../../Pages/App/App.less";
import { useHistory, useLocation } from "react-router-dom";
import LogoEveia from "../../assets/Images/LogoEveia.png";
import ButtonCustom from "../ButtonCustom";
import LanguageSelector from "../../Pages/LanguageSelector";
import { useTranslation } from "react-i18next";

import {
  UserOutlined,
  BankOutlined,
  AppstoreAddOutlined,
  ThunderboltOutlined,
  CrownOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  LogoutOutlined,
  ShopOutlined,
  RiseOutlined,
  LaptopOutlined
} from "@ant-design/icons";

const pages = {
  1: "/users",
  2: "/devices",
  3: "/companies",
  4: "/accountPlans",
  5: "/badges",
  6: "/activities",
  7: "/challenges",
  8: "/worlds",
  9: "/rooms",
  10: "/sessions"
};

const HeaderMenu = () => (
  <Row className="headerMenu">
    <Col span={15} className="logoMenuContainer">
      <img className="logoMenu" src={LogoEveia} alt="img" />
    </Col>
    <Col span={9} className="traduction">
      <LanguageSelector />
    </Col>
  </Row>
);

const FooterMenu = ({ history, t }) => (
  <div>
    <ButtonCustom
      onClick={() => history.push("/logout")}
      title={t("button:Logout")}
      type="ghost"
      icon={<LogoutOutlined />}
      className="logout"
      block={true}
    />
  </div>
);

const MainMenu = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const history = useHistory();

  const [selectedKey, setSelectedKey] = useState(
    Object.keys(pages)
      .filter((k) => pages[k] === history.location.pathname)
      .toString()
  );

  const goToPage = (key) => {
    history.push(pages[key]);
  };

  useEffect(() => {
    setSelectedKey(
      Object.keys(pages)
        .filter((k) => pages[k] === location.pathname)
        .toString()
    );
  }, [location.pathname]);

  return (
    <Layout.Sider width={300} className="sider">
      <Menu
        mode="inline"
        className="mainMenu"
        selectedKeys={[selectedKey]}
        defaultSelectedKeys={["1"]}
      >
        <HeaderMenu />

        <Menu.Item
          icon={<UserOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="1"
          className="mainMenuContent"
        >
          {t("Users")}
        </Menu.Item>

        <Menu.Item
          icon={<LaptopOutlined className="row" />}
          onClick={(e) => goToPage(e.key)}
          key="2"
          className="mainMenuContent"
        >
          {t("Devices")}
        </Menu.Item>

        <Menu.Item
          icon={<TeamOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="3"
          className="mainMenuContent"
        >
          {t("Companies")}
        </Menu.Item>

        <Menu.Item
          icon={<DollarCircleOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="4"
          className="mainMenuContent"
        >
          {t("Account plans")}
        </Menu.Item>

        <Menu.Item
          icon={<CrownOutlined className="row" />}
          onClick={(e) => goToPage(e.key)}
          key="5"
          className="mainMenuContent"
        >
          {t("Badges")}
        </Menu.Item>

        <Menu.Item
          icon={<AppstoreAddOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="6"
          className="mainMenuContent"
        >
          {t("Activities")}
        </Menu.Item>

        <Menu.Item
          icon={<RiseOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="7"
          className="mainMenuContent"
        >
          {t("Challenges")}
        </Menu.Item>

        <Menu.Item
          icon={<BankOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="8"
          className="mainMenuContent"
        >
          {t("Worlds")}
        </Menu.Item>

        <Menu.Item
          icon={<ShopOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="9"
          className="mainMenuContent"
        >
          {t("Rooms")}
        </Menu.Item>

        <Menu.Item
          icon={<ThunderboltOutlined />}
          onClick={(e) => goToPage(e.key)}
          key="10"
          className="mainMenuContent"
        >
          {t("Sessions")}
        </Menu.Item>
        <FooterMenu t={t} history={history} />
      </Menu>
    </Layout.Sider>
  );
};

export default MainMenu;
