import React from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({ id, setUser, setMachine }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:filterUserId.label")}
            placeholder={t("input:filterUserId.placeholder")}
            onChange={setUser}
            debounceTimeout={500}
          />
        </Col>

        <Col span={11}>
          <Input
            title={t("input:filterMachine.label")}
            placeholder={t("input:filterMachine.placeholder")}
            onChange={setMachine}
            debounceTimeout={500}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
