import { gql } from "@apollo/client";

export const GET_BADGES = gql`
  query getAllBadges(
    $page: Int
    $number: Int
    $title: String
    $daily: Boolean
  ) {
    getAllBadges(
      infos: {
        page: $page
        number: $number
        title: $title
        daily: $daily
      }
    ) {
      id
      title
      daily
      rules {
        calories
        laps
      }
    }
  }
`;

export const DELETE_BADGES = gql`
  mutation deleteMultipleBadges($ids: [ID]!) {
    deleteMultipleBadges(ids: $ids)
  }
`;

export const CREATE_BADGE = gql`
  mutation createBadge(
    $daily: Boolean
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $laps: Int
    $calories: Int
    $image: String
  ) {
    createBadge(
      infos: {
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        calories: $calories
        laps: $laps
        daily: $daily
        image: $image
      }
    ) {
      id
      title
      daily
      rules {
        calories
        laps
      }
    }
  }
`;

export const GET_BADGE = gql`
  query getBadge($id: ID!) {
    getBadge(id: $id) {
      id
      daily
      image
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      rules {
        calories
        laps
      }
    }
  }
`;

export const UPDATE_BADGE = gql`
  mutation updateBadge(
    $id: ID!
    $titleFr: String
    $titleEN: String
    $subFr: String
    $subEN: String
    $laps: Int
    $calories: Int
    $image: String
    $daily: Boolean
  ) {
    updateBadge(
      id: $id
      infos: {
        subtitle: { en: $subEN, fr: $subFr }
        title: { en: $titleEN, fr: $titleFr }
        calories: $calories
        laps: $laps
        daily: $daily
        image: $image
      }
    ) {
      id
      daily
      image
      title
      subtitle
      titleAll {
        en
        fr
      }
      subtitleAll {
        en
        fr
      }
      rules {
        calories
        laps
      }
    }
  }
`;
