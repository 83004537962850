const emailErrorMessage = 'Email address not valid';

export const validateEmail = (email) => {
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (reg.test(email))
    return '';
  else
    return emailErrorMessage;
};

const passwordTooShortMessage = 'Password too short';
const passwordNoLowCaseMessage = 'No lowercase caracter in the password';
const passwordNoUpCaseMessage = 'No uppercase caracter in the password';
const passwordNoNumber = 'No digit caracter in the password';
const passwordNoSpecialMessage = 'No special caracter in the password';

export const validatePassword = (password) => {
  const regCaseLow = /^(?=.*[a-z]).*$/;
  const regCaseUp = /^(?=.*[A-Z]).*$/;
  const regNumber = /^(?=.*[0-9]).*$/;
  const regSpecial = /^(?=.*[^A-Za-z0-9]).*$/;

  if (password.length < 8)
    return passwordTooShortMessage;
  else if (!(regCaseLow.test(password)))
    return passwordNoLowCaseMessage;
  else if (!(regCaseUp.test(password)))
    return passwordNoUpCaseMessage;
  else if (!(regNumber.test(password)))
    return passwordNoNumber;
  else if (!(regSpecial.test(password)))
    return passwordNoSpecialMessage;
  else
    return '';
}

const invalidePhoneNumberMessage = 'Phone number not valid';

export const validatePhoneNumber = (number) => {
  const phoneReg = /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/;
  if (!(phoneReg.test(number)))
    return invalidePhoneNumberMessage;
  return '';
};