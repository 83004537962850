import React, { useState } from "react";
import { Row, Col } from "antd";
import ButtonCustom from "../ButtonCustom";
import "./HeaderStyle.less";
import { FilterFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Header = ({ children, name, canShowFilters }) => {

  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);
   
  return (
    <div className="topHeader">
      <Row>
        <Col span={16} className="titleHeader"> {name} </Col>
        {canShowFilters ? (
          <Col span={8} className="funnel">
            <div className="titleFilter">{t("button:Filter")}</div>
            <ButtonCustom
              icon={<FilterFilled className="funnelIcon" />}
              shape="circle"
              size="large"
              onClick={() => setShowFilters(!showFilters)}
              className="noBorder"
            />
          </Col>
        ) : null}
      </Row>
      {showFilters ? children : null}
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  canShowFilters: PropTypes.bool
};

Header.defaultProps = {
  name: "USERS",
  canShowFilters: true
};

export default Header;
