import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import CopyId from "../../../Components/CopyId";

export const getContentColumns = (t) => {
    const columns = [
        {
        title: <div className="tableHeading">{t("ID")}</div>,
        dataIndex: "id",
        render: (id) => <CopyId id={id} />
        },
        {
        title: <div className="tableHeading">{t("TITLE")}</div>,
        dataIndex: "title",
        render: (title) => <div className="tableValue">{title}</div>
        },
        {
        title: <div className="tableHeading">{t("DAILY")}</div>,
        dataIndex: "daily",
        render: (daily) => (
            !daily ? <CloseCircleOutlined className="closeOutlined"/> 
            : <CheckCircleOutlined className="checkOutlined"/>
          )
        }
    ];

     return columns;
}

export const getContentRows = (data) => {
  return ( 
    !data || !data.getAllBadges
      ? []
      : data.getAllBadges.map(({ id, title, daily }) => ({
          key: id,
          id,
          title,
          daily
        })
    )
  )
}

export const checkIfNextDisabled = (data, number) => {
  if( !data || 
      !data.getAllBadges || 
      data.getAllBadges.length === 0 ||
      data.getAllBadges.length < number
    ) return true;
  return false;
}
