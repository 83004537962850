import React from "react";
import { Col } from "antd";
import Input from "../../Components/Input";
import "../../UI/CommonStyles.less";
import { useTranslation } from "react-i18next";

const Filters = ({ setName }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            placeholder={t("input:name.placeholder")}
            onChange={(text) => setName(text)}
            debounceTimeout={500}
          />
        </Col>
    </React.Fragment>
  );
};

export default Filters;
