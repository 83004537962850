import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";
import "./ResetPasswordStyle.less";
import LogoEveia from "../../assets/Images/LogoEveia.png";
import ButtonCustom from "../../Components/ButtonCustom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  ErrorText,
  StyledInputContainer,
  StyledLabelInput,
  Text
} from "./styledComponent";

const RESET_AND_UPDATE_PASSWORD = gql`
  mutation resetAndUpdatePassword($token: String!, $password: String!) {
    resetAndUpdatePassword(token: $token, password: $password)
  }
`;

const ResetPassword = () => {
  let { token } = useParams();
  const [isResettedPassword, setIsResettedPassword] = useState(null);
  const { t } = useTranslation(["input", "button"]);
  const [resetAndUpdatePassword] = useMutation(RESET_AND_UPDATE_PASSWORD, {
    onCompleted: (data) => setIsResettedPassword(data.resetAndUpdatePassword)
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ mode: "onChange" });

  const onSubmit = (data) => {
    resetAndUpdatePassword({
      variables: {
        password: data.password,
        token: token
      }
    });
  };
  const password = watch("password");
  const passwordConfirmation = watch("passwordConfirmation");

  const regPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/; // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ResetPasswordForm">
      <section className="ResetPasswordSection">
        <img src={LogoEveia} alt="img" width="220" height="57" />
      </section>

      {isResettedPassword ? (
        <Text>✅ Votre mot de passe à été changé avec succès</Text>
      ) : (
        <>
          <div style={{ margin: "10px 0px" }}>
            <div style={{ marginBottom: 20 }}>
              <StyledLabelInput error={!!errors?.password}>
                * Nouveau mot de passe:
              </StyledLabelInput>
              <StyledInputContainer error={!!errors?.password}>
                <input
                  className="inputresetPassword"
                  {...register("password", {
                    required: true,
                    pattern: regPassword
                  })}
                />
              </StyledInputContainer>
            </div>

            <div>
              {passwordConfirmation !== password && (
                <ErrorText>⚠️ Le mot de passe ne correspond pas</ErrorText>
              )}
              <StyledLabelInput error={!!errors?.passwordConfirmation}>
                * Confirmation du nouveau mot de passe:
              </StyledLabelInput>
              <StyledInputContainer error={!!errors?.passwordConfirmation}>
                <input
                  className="inputresetPassword"
                  {...register("passwordConfirmation", {
                    required: true
                  })}
                />
              </StyledInputContainer>
            </div>
          </div>
          <ButtonCustom
            title={t("button:Submit")}
            type="primary"
            htmlType="submit"
            className="buttonSubmit"
            disabled={errors && passwordConfirmation !== password}
          />
        </>
      )}

      {errors?.password?.type === "pattern" ? (
        <>
          <ErrorText>⚠️ Le mot de passe doit contenir au moins:</ErrorText>
          <ErrorText style={{ marginBottom: 10 }}>
            <ErrorText valid={password.length >= 8}>- 8 caractères</ErrorText>
            <ErrorText valid={/[A-Z]/.test(password)}>
              - 1 lettre majuscule
            </ErrorText>
            <ErrorText valid={/[a-z]/.test(password)}>
              - 1 lettre minuscule
            </ErrorText>
            <ErrorText valid={/\d/.test(password)}>- 1 chiffre</ErrorText>

            <ErrorText valid={/[@$!%*?&-]/.test(password)}>
              {`- 1 caractère spécial parmi [@$!%*?&-]`}
            </ErrorText>
          </ErrorText>
        </>
      ) : (
        <>
          <ErrorText>
            {errors?.password?.type === "required" && "Champs requis"}
          </ErrorText>
        </>
      )}
    </form>
  );
};

export default ResetPassword;
