import React, { useState } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import CheckboxCustom from "../../Components/CheckboxCustom";
import InputNumber from "../../Components/InputNumber";
import "../../UI/CommonStyles.less";
import { CREATE_ACCOUNTPLAN } from "./Requests";
import { useMutation } from "@apollo/client";
import EditContainer from "../../Components/EditContainer";
import { useTranslation } from "react-i18next";
import { launchCreationAccountPlan } from "./Utility/CreateAccountPlanUtility";


const CreateAccountPlan = ({ onClose, history, refetch }) => {
  const { t } = useTranslation();
  const [infos, setInfos] = useState({});
  const [createAccountPlan] = useMutation(CREATE_ACCOUNTPLAN);

  const checkIfHasChanged = () => {
    if (infos && infos.name) return true;
    return false;
  };

  const handleChange = (type, value) => {
    const newInfos = { ...infos };
    newInfos[type] = value;
    setInfos(newInfos);
  };

  return (
    <EditContainer
      onSave={() => launchCreationAccountPlan(
        onClose, refetch, infos, setInfos, createAccountPlan
      )}
      title={t("Create AccountPlan")}
      onClose={onClose}
      saveDisabled={!checkIfHasChanged()}
    >
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:name.label")}
            placeholder={t("input:name.placeholder")}
            onChange={(value) => handleChange("name", value, infos)}
          />
        </Col>

        <Col span={11}>
          <Input
            title={t("input:promotionalCode.label")}
            placeholder={t("input:promotionalCode.placeholder")}
            onChange={(value) => handleChange("promotionalCode", value, infos)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <InputNumber
            title={t("input:duration.label")}
            placeholder={t("input:duration.placeholder")}
            onChange={(e) => handleChange("duration", e, infos)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Active")}
            onChange={(e) => handleChange("active", e, infos)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Friendship")}
            onChange={(e) => handleChange("friendship", e, infos)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:History Activities")}
            onChange={(e) => handleChange("activityHistory", e, infos)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Basic Activities")}
            onChange={(e) => handleChange("basicActivities", e, infos)}
          />
        </Col>
      </Row>

      <Row className="horizontalRowStyle">
        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:Multi Activities")}
            onChange={(e) => handleChange("multiActivities", e, infos)}
          />
        </Col>

        <Col span={11}>
          <CheckboxCustom
            title={t("checkbox:VideoGame")}
            onChange={(e) => handleChange("videogame", e, infos)}
          />
        </Col>
      </Row>
    </EditContainer>
  );
};

export default CreateAccountPlan;
