import { gql } from '@apollo/client';

export const GET_SESSIONS = gql`
  query getAllSession (
    $number: Int
    $page: Int
    $activity: String
    $room: String
    $beginDate: Date
    $endDate: Date
    $user: String
    $status: String
  ) {
    getAllSession (
      infos: {
        number: $number
        page: $page
        activity: $activity
        room: $room
        beginDate: $beginDate
        endDate: $endDate
        user: $user
        status: $status
      }
    ) {
      id
      activity
      room
      daily
      user
      status
      beginDate
      endDate
      updateDate
      time
      calories
      laps
      lapsOffline
      caloriesOffline
      lastPosition {
        x
        y
        z
      }
    }
  }
`;

export const DELETE_SESSIONS = gql`
  mutation deleteSessionsByAdmin ($ids: [ID!]) {
    deleteSessionsByAdmin (ids: $ids)
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession (
    $id: ID!
    $status: String
  ) {
    updateSession (
      id: $id
      infos: {
        status: $status
      }
    ) {
      id
      status
    }
  }
`;

export const GET_SESSION_LOGS = gql`
  query getLogSessionBySession ($id: ID!) {
    getLogSessionBySession (id: $id) {
      id
      session
      log {
        force
        laps
        lapsOffline
        caloriesOffline
        timeLaps
        interval
        x
        y
        z
      }
    }
  }
`;

export const GET_SESSION = gql`
  query getSession ($id: ID!) {
    getSession (id: $id) {
      id:
      activity {
        id
        titleAll {
          fr
        }
      }
      user {
        id
        email
      }
      status
      daily
      beginDate
      endDate
      time
      calories
      laps
      lapsOffline
      caloriesOffline
    }
  }
`;

