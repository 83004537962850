import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import Input from "../../Components/Input";
import "../../UI/CommonStyles.less";
import { GET_COMPANIES } from "../Companies/Requests";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const Filters = ({ setName, setEmail, setCompany }) => {
  const { t } = useTranslation();
  const companiesRequest = useQuery(GET_COMPANIES);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if ( companiesRequest?.data?.getAllCompanies )
      setCompanies(companiesRequest.data.getAllCompanies);
  }, [companiesRequest]);

  const companiesData = companies.map((item) => {
    return item.name;
  });
  companiesData.unshift("...");

  return (
    <React.Fragment>
      <Row className="horizontalRow">
        <Col span={11}>
          <Input
            title={t("input:filterName.label")}
            placeholder={t("input:filterName.placeholder")}
            onChange={(text) => setName(text)}
            debounceTimeout={500}
          />
        </Col>
        <Col span={11}>
          <Input
            title={t("input:filterEmail.label")}
            placeholder={t("input:filterEmail.placeholder")}
            onChange={(text) => setEmail(text)}
            debounceTimeout={500}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Filters;
