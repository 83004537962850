import styled from "styled-components";

export const StyledInputContainer = styled.div`
  all: unset;
  flex: 1;
  border-style: solid;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
  border-width: 1px;
  border-color: ${(props) => (props.error ? "#e0991c" : "#EBEDEF")};
  border-radius: 8px;
  background-color: ${(props) => (props.error ? "#fef8e9" : "#fff")};
  margin-bottom: ${(props) => (props.spacebottom ? props.spacebottom : 0)}px;
`;

export const ErrorText = styled.div`
  font-size: 16px;
  margin: 0;
  color: ${({ valid }) => (valid ? "green" : "#e0991c")};
`;

export const StyledLabelInput = styled.p`
  font-size: 18px;
  color: ${(props) => props.error && "#e0991c"};
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 18px;
  color: ${(props) => props.error && "#e0991c"};
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 600;
`;
